import React, { useState, useEffect } from "react";
import { useStateValue } from "../components/context/Context";

import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Subtotal from "./Subtotal";
import "./ProductDesc.css";
let tempArray = [];

const ProductDesc = ({}) => {
  const [{ basket }, dispatch] = useStateValue();
  const [localItem, setLocalItem] = useState(
    JSON.parse(localStorage.getItem("basketItem")) || []
  );
  // const [{ basket }, dispatch] = useStateValue();
  // JSON.parse(localStorage.getItem("basketItem")) ||
  useEffect(() => {
    // console.log(data.products, "hi this is storage item 1");
    if (
      JSON.parse(localStorage.getItem("basketItem")) &&
      tempArray.length == 0
    ) {
      tempArray = JSON.parse(localStorage.getItem("basketItem"));
    }
    // console.log(tempArray,"ddddddddddddddddddddd");
    localStorage.setItem("basketItem", JSON.stringify(tempArray));
    dispatch({
      type: "ADD_TO_BASKET",
      item: tempArray,
    });
    // console.log(basket, "hi this is storage item");
  }, [localItem]);
  const navigate = useNavigate();
  const addToBasket = (e) => {
    // dispatch some action into the data layer\

    let newItem = {
      
      title: location.state.name1,
      image: location.state.image1,
      price: location.state.price1,
    };

// console.log(newItem,"gvytgybigbibgub");
    dispatch({
      type: "ADD_TO_BASKET",
      item: newItem,
    });
    // console.log(newItem, "add to basket");
    tempArray.push(newItem);
    // // console.log(localItem, "boefore");
    setLocalItem([...localItem, newItem]);
    // console.log(localItem, "after");
    // navigate("/checkout", { state: newItem });
    // console.log(tempArray,"geeeeeeeee");
  };
  const location = useLocation();
  // console.log(location, "lovtuyfiyygviy");

  return (
    <div className="MainConatinerProductDesc">
      <div className="NavbarForProductDesc">
        <Navbar />
      </div>

      {/*{location.state.descc} this is desc
  {location.state.name1} this is anme*/}
      <div className="ProductDescHeading">{location.state.name1}</div>
      <div className="ProductDescLeftAndRight">
        <div className="ProductDescLeft">
          <div className="ProductDescImgDiv">
            <img
              className="ProductDescImg"
              src={location.state.image1}
              alt={location.state.image1}
            />
          </div>
          <div className="ProductDescInfoContent">
            <div className="ProductDescPrice">{location.state.price1}</div>
            <div className="ProductDescInfoContentHeading">
              {location.state.name1}
            </div>
            <div className="ProductDescInfoContentQuantity"></div>
            <div className="ProductDescInfoContentDescDiv">
              <div className="ProductDescInfoContentDescription">
                {location.state.descc}
              </div>
            </div>
            <div className="ProductDescInfoContentButtonDiv">
              <button className="ProductDescInfoContentButton">Buy Now</button>
              <button
                className="ProductDescInfoContentButtonAddToCart"
                onClick={addToBasket}
              >
                Add To Cart
              </button>
            </div>
          </div>
        </div>
        <div className="ProductDescRight">
          <div className="YourSubtotalProductDesc">
            <Subtotal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDesc;
