import React from 'react'
import './Subtotal.css'
import CurrencyFormat from 'react-currency-format'
import { useStateValue } from './context/Context'
import { getBasketTotal } from './context/Reducer'
import { useNavigate } from 'react-router-dom'
const Subtotal = () => {

  const [{basket},dispatch]= useStateValue();
// charging payment
const handleToken=(token)=>{
  // console.log(token);
}

const navigate = useNavigate();
  return (
    <div className='subtotalComponent'>
    <CurrencyFormat
    
    renderText={(value)=>(
     
        <>
        <p>
        Subtotal ({basket?.length} items):
        <strong>
       {value}
        </strong>
        </p>
       
        
        </>
  )}
    decimalScale={2}
    value={getBasketTotal(basket)}
    displayType={'text'}
    thousandSeparator={true}
    prefix={'$'}
    /> 
    <div>
    <button className='proceedToCheckoutButton'
    onClick={e=>navigate('/paymentPage')}>Proceed To Checkout
    </button>
    </div>
    </div>
  )
}

export default Subtotal