export const initialState = {
  basket: [],
  user: null,
};
// selector
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.price + amount, 0);
const reducer = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "ADD_TO_BASKET":
      if ((action.item).length) {
        return {
          ...state,
          basket: action.item,
        };
      }
      return {
        ...state,
        basket: [...state.basket, action.item],
      };

    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };

      

    default:
      return state;
  }
};

export default reducer;
