
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import Katelyn from "../../images/Katelyn.png";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const KatelynNForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerArtistsKatelyn'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImgArtists" src={Katelyn}/>
    <div className="KeratinLashLiftForNavbarInfoAnnosune">
    <div className="KeratinLashLiftForNavbarHeadingArtists"> Katelyn N</div>
    <div className="KeratinLashLiftForNavbarPrice">     (559) 530-0309</div>
    <div className="KeratinLashLiftForNavbarContentArtists"> I take pride in the highest of quality, control, and education,I am recognized by the world renowned, National Association of Lash Artists. Providing knowledge to my clients as well students is a must, Education is key! I am a/an *NALA educator *License cosmetologist, in the State of CA *Certified Bella Lash Artist. *Owner and Co-Founder of EmbelLashes Lashes & Brows I Love to make women look and feel more beautiful. Lashes enhance your natural beauty! Book an Appointment or Take a Class with me today!
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnArtists">
    <button className="buyNowKeratinLashLiftForNavbarBtnArtists"> Schedule An Appointment Now!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default KatelynNForNavbar