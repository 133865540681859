import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import upperLipWaxingImg from '../../images/upperLipWaxing.jpg'
import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainer">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatiner">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={upperLipWaxingImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoWaxing">
              <div className="KeratinLashLiftForNavbarPrice">$15.00</div>
              <div className="UpperLipWaxingForNavbarHeading">
              Upper Lip Waxing 
              </div>
              <div className="UpperLipWaxingForNavbarContent">
              Have painless upper-lip waxing for just $15!
                <br></br>
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowUpperLipWaxingForNavbarBtn">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatiner">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={InLeiLashLiftAndFillersImg}
            />
            <div className="KeratinLashLiftForNavbarInfoWaxing">
              <div className="KeratinLashLiftForNavbarPrice">$80.00</div>
              <div className="KeratinLashLiftForNavbarHeading forSorSideburnWaxingOfNavbarHeading">
              Sideburns Waxing
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Take sideburns waxing to go your makeup on your face evenly


              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforSideburnWaxingInNavbar">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatiner">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={InLeiLashLiftAndFillersImg}
            />
            <div className="KeratinLashLiftForNavbarInfoWaxing">
              <div className="KeratinLashLiftForNavbarPrice">$60.00</div>
              <div className="KeratinLashLiftForNavbarHeading forFullFacWaxingOfNavbarHeading">
              Full Face Waxing
              </div>
              <div className="FullfaceWaxingForNavbarContent">
              Have smooth and hassle-free skin for weeks with our full face waxing.


                   </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforFullFaceWaxingOfNavbar">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatiner">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={InLeiLashLiftAndFillersImg}
          />
          <div className="KeratinLashLiftForNavbarInfoWaxing">
            <div className="KeratinLashLiftForNavbarPrice">$25.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            Brows waxing
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            Have less painful brows waxing than threading. Just at $ 25!



</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforBrowsWaxingforNavbar">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      
      </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
