import React from 'react'
import './KertainLashLiftPage.css'
import KeratinLashLiftServiceImg from '../images/KeratinlashLiftService.jpg'
import InLeiLashLiftAndFillersImg from '../images/InLeiLashLiftAndFillersImg.jpg'
import BookNowButton from '../components/Buttons/BookNowButton'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MobileNavbar from '../components/Accordion/MobileNavbar'
const KeratinLashLiftServicePage = () => {
  return (
    <div className='KeratinLashLiftServiceMainContainer'>
    <div className='NavbarConatinerOfKeratinlashLiftpage'>
    <Navbar/></div>
    <div className='mobileNavbarRemoval'><MobileNavbar/></div>
    <div className='HeadingOfKeratinlashLiftPage'> Our Removal services</div>
      <div className='KeratinLashLiftServiceSection'>
      <img className='KeratinLashLiftServiceimage' src={KeratinLashLiftServiceImg}/>
        <div className='KeratinLashLiftServiceHeading'>
        <div className='KeratinEyelashLiftPrice'>$35.00</div>
         <h1 className='KeratinLashLiftServiceSectionHeadingInfo'>
         Eye Make-up Removal Service </h1>
    
            <div className='KeratinLashLiftServiceSectionInfo'>Please come super clean. Our Lash Artist will only work with clean Lashes! At EmbelLASH Studio we will get give consistency and quality service. As a Bella Lash Sponsored Lash Artist, EmbelLASHes works with on top tier health benefitting companies and proudly represents and recommends Bella Lash Care and After Care beauty products.
            </div>
            <div className='bookNowButtonForKeratinLashLiftServicePage'>  <BookNowButton/></div>
  
        </div>
      </div>
      <div className='InleiLashListServiceSectionForGivingMargin'>
      <div className='KeratinLashLiftServiceSection'>
      <img className='KeratinLashLiftServiceimage' src={InLeiLashLiftAndFillersImg}/>
        <div className='KeratinLashLiftServiceHeading'>
        <div className='KeratinEyelashLiftPrice'>$40.00</div>
         <h1 className='KeratinLashLiftServiceSectionHeadingInfo'>
         Eyelash extension Removal </h1>
    
            <div className='KeratinLashLiftServiceSectionInfo'>Removal of extensions safely without harming natural Lash
            </div>
            <div className='bookNowButtonForKeratinLashLiftServicePage'>  <BookNowButton/></div>
  
        </div>
      </div>
     </div>
      <Footer/>
    </div>
  )
}

export default KeratinLashLiftServicePage