import React from "react";
import "./BuyNow.css";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import ProductDesc from "../ProductDesc";
import { Route, Routes } from "react-router-dom";
const BuyNow = ({ description ,name,price,image,id}) => {
  const navigate = useNavigate();
  const toProductDesc = () => {
    navigate("/ProductDesc", { state: { descc: description,
      name1:name,
      price1:price,
      image1:image,
   
     }
   })
 
  };
  return (
    <div>
      <button onClick={toProductDesc} className="btnBuyNow">
        Buy Now
      </button>
    </div>
  );
};

export default BuyNow;
