import React from 'react'

const individidualArtist = ({individualArtist}) => {
  return (
    <div className='bgAsBody'>
   <div className='card'>
        <div className='circle'>

        </div>
        <div className='content'>
        <h2>{individualArtist.title}</h2>
        <p>{individualArtist.artistsDescription}</p>
        </div>
        <img src={individualArtist.url} ></img>
   </div>
   </div>
  )
}

export default individidualArtist