import React, { useRef, useState } from "react";
// Import Swiper React components
import KeratinlashLiftServiceImg from "../../images/MicrobladePlusShadeImg.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import waxingEyelasExtensionPage from "../../images/Eyebrow-Touch-Up.jpg";
import classicServiceImg from "../../images/FrecklesImg.jpg";
import hybridServiceImg from "../../images/HennaEyebrowsiMg.jpg";
import volumeServiceImg from "../../images/eyebrowLamination.jpg";
import megaVolumeServiceImg from "../../images/angelRemovalImg.jpg";
import removalServiceImg from "../../images/katelynMicrobladingForeignTouchupImg.jpg";

import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerBrowsBykatelyn">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="BrowsBykatelynForNavbarConatiner">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={KeratinlashLiftServiceImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoBrowsBykatelyn">
              <div className="BrowsBykatelynPriceForNavBar">$500.00</div>
              <div className="BrowsBykatelynForNavbarHeading">
              MICROBLADE + SHADE
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Redesign your eyebrows to create a more youthful or stylish look<br/><br/>
             
              Hair-like strokes mimic natural brows<br/><br></br>
              Lasts 1-2 years with one touch-up session<br/><br/>
              No machines, no downtime<br/><br/>
            
            Microblading is ideal for individuals who desire to enhance the
            appearance of their eyebrows.
           
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowKeratinLashLiftForNavbarBtnBrowsByKatelyn">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="BrowsBykatelynForNavbarConatiner">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={waxingEyelasExtensionPage}
            />
            <div className="KeratinLashLiftForNavbarInfoBrowsBykatelynEyebrowTouchUp">
              <div className="BrowsBykatelynPriceForNavBar">$250.00</div>
              <div className="BrowsBykatelynForNavbarHeading ">
              Eyebrow Touch Up
              </div>
              <div className="eyebrowTouchUpForNavbarContent">
              Must be confirmed with Katelyn.<br/><br/> Fill for reoccurring Microblade + Shade clients<br/><br/>
     <br></br>

              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowEyebrowTouchUpForNavbarBtn">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="BrowsBykatelynForNavbarConatiner">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={classicServiceImg}
            />
            <div className="KeratinLashLiftForNavbarInfoBrowsBykatelyn">
              <div className="BrowsBykatelynPriceForNavBar">$100.00</div>
              <div className="BrowsBykatelynForNavbarHeading ">
              Freckels
              </div>
              <div className="KeratinLashLiftForNavbarContentFreckels">
              Freckle tattooing is a procedure in which a makeup artist inserts pigment in your cheeks and nose, to give you the perfect freckly look         </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtnBrowsByKatelynFreckels">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="BrowsBykatelynForNavbarConatiner">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={hybridServiceImg}
          />
          <div className="HennaEyebrowsForNavbarInfo">
            <div className="BrowsBykatelynPriceForNavBar">$150.00</div>
            <div className="BrowsBykatelynForNavbarHeadingHennaEyebrows ">
            Henna Eyebrows
            </div>
            <div className="HennaEyebrowsForNavbarContent">
            More definition by adding color to brows.

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowHennaEyebrowsForNavbarBtn ">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="BrowsBykatelynForNavbarConatiner">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={volumeServiceImg}
          />
          <div className="HennaEyebrowsForNavbarInfo">
            <div className="BrowsBykatelynPriceForNavBar">$150.00</div>
            <div className="BrowsBykatelynForNavbarHeadingEyebrowLamination ">
            Eyebrow Lamination 
            </div>
            <div className="EyebrowLaminationForNavbarContent">
            Enhancing brows for a fuller look
         

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowEyebrowlaminationLiftForNavbarBtn">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="BrowsBykatelynForNavbarConatiner">
        <img
          className="KeratinLashLiftForNavbarImg"
          src={megaVolumeServiceImg}
        />
        <div className="KeratinLashLiftForNavbarInfoBrowsBykatelyn">
          <div className="BrowsBykatelynPriceForNavBar">$155.00</div>
          <div className="BrowsBykatelynForNavbarHeading ">
          Angel Removal
          </div>
          <div className="KeratinLashLiftForNavbarContent">
          Redesign your eyebrows to create a more youthful or stylish look<br/>
         
        (One session) Removal solution for previous eyebrows and lip liner permanent makeup. PLEASE NOTE: Treatment for eyebrows only- not for eyeliner, or body tattoos. <br/><br/>
            Wear brow makeup again after just 24 hours! Number of sessions vary for each client to achieve optimal results.<br/><br/>
        A 50% deposit is required to schedule your appointment. Reschedules are required within 48 hours of your appointment.
       

</div>
          <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
            <button className="buyNowKeratinLashLiftForNavbarBtnBrowsByKatelyn ">
              Get Your Slot Now!!
            </button>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="BrowsBykatelynForNavbarConatiner">
      <img
        className="KeratinLashLiftForNavbarImg"
        src={removalServiceImg}
      />
      <div className="KeratinLashLiftForNavbarInfoBrowsBykatelyn">
        <div className="BrowsBykatelynPriceForNavBar">$350.00</div>
        <div className="BrowsBykatelynForNavbarHeading ">
        Microblading Foreign Touch up
        </div>
        <div className="KeratinLashLiftForNavbarContent">
        Microblading Touch up. Client has previous work done by another Brow Artist. Must be 12 weeks healed from previous appointment.
     

</div>
        <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
          <button className="buyNowKeratinLashLiftForNavbarBtnBrowsByKatelyn btnforInlashLift">
            Get Your Slot Now!!
          </button>
        </div>
      </div>
    </div>
  </SwiperSlide>
     
     </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
