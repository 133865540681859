import React from 'react'
import { useState ,useRef,useEffect} from 'react'
import './MyAccordion.css'
import MyAccordionChild from './MyAccordionChild'
import {MdKeyboardArrowDown} from 'react-icons/md'
const MyAccordion = ({navItem1,navItem1Child,path}) => {
    const [show, setShow] = useState(false)
    // const [showcolor, setShowColor] = useState(sty)

    useEffect(() => {
      document.addEventListener("mousedown", (event) => {
        if (!menuRef.current.contains(event.target)) {
          setShow(false);
        }
      });
    }, []);
    let menuRef = useRef();
if( navItem1Child){
  return (
    <div>
   
   <div className='main-heading-MyAccoridion'>
   <a href={path || '#'} > <h1 className='MyAccordion-Item1'>{navItem1}</h1></a>
   <div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>

   </div>
   {show && 
   <h2 className='MyAccordion-Item2'>{navItem1Child.map((curElm)=>{
    const {navItem1}= curElm;
    
    return(
      <div><MyAccordionChild key={navItem1} {...curElm}  /></div>
        
        
    )
   })}</h2>
   }

    </div>
  )
}else{
  return (
    <div>
   
   <div className='main-heading-MyAccoridion'>
   <a href={path || '#'} > <h1 className='MyAccordion-Item1'>{navItem1}</h1></a>
   {/*<div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>*/}

   </div>
   {show && 
   <h2 className='MyAccordion-Item2'>{navItem1Child.map((curElm)=>{
    const {navItem1}= curElm;
    
    return(
        <MyAccordionChild key={navItem1} {...curElm} />
        
    )
   })}</h2>
   }

    </div>
  )
}
}

export default MyAccordion