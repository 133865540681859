import React ,{useState}from 'react'
import {MdKeyboardArrowDown} from 'react-icons/md'
import './MyAcoordionChildsChild.css'

const MyAccordionChildsChild = ({navItem2,path,navItem1Child1}) => {
    const [show, setShow] = useState(false)
    if( navItem1Child1){
  return (
    <div>
    <div className='main-heading-MyAccoridionChildsChild'>
    <a href={path || '#'} > <h4 className='MyAccordion-Item1ChildsChild'>{navItem2}</h4></a>
    <div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>
 
    </div>
    {show && 
    <h2 className='MyAccordion-Item2'>{navItem1Child1.map((curElm)=>{
     const {navItem2}= curElm;
    //  console.log(curElm);
     return(
        <a  className='MyAccordion-Item2Child' href={path || '#'} key={navItem2} {...curElm}>
        {navItem2}
        </a>
      
        
    )
   })}</h2>
   }
   </div>
  )
}else{
    return (
        <div>
        <div className='main-heading-MyAccoridionChildsChild'>
        <a href={path || '#'} > <h4 className='MyAccordion-Item1ChildsChild'>{navItem2}</h4></a>
        {/*<div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>*/}
     
        </div>
        {show && 
        <h2 className='MyAccordion-Item2'>{navItem1Child1.map((curElm)=>{
         const {navItem2}= curElm;
        //  console.log(curElm);
         return(
            <a  className='MyAccordion-Item2Child' href={path || '#'} key={navItem2} {...curElm}>
            {navItem2}
            </a>
          
            
        )
       })}</h2>
       }
       </div>
      ) 
}
}

export default MyAccordionChildsChild