import MicroBladingProductPageImg from "../images/microbladingPageNewImg.jpg";
import "./MicroBlading.css";
import MicroBladingProductPage1stImgOfSlider from "../images/MicroBladingPage2ndImage.jpg";
import MicroBladingProductPage2ndImgOfSlider from "../images/MicrobladingPageSliderImg2.PNG";
import MicroBladingProductPage3rdImgOfSlider from "../images/MicrobladingSliderImg3.PNG";
import MicroBladingProductPage4thImgOfSlider from "../images/MicrobladingSLiderImg4.PNG";
import MicroBladingProductPage5thImgOfSlider from "../images/MicrobladingSliderImg5.PNG";
import MicroBladingProductPage6thImgOfSlider from "../images/MicrobladingSliderImg6.PNG";
import { db } from "../Firebase";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BookNowButton from "../components/Buttons/BookNowButtonMicroBladingPage";
import React, { useRef, useState ,useEffect} from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {
  Autoplay,
  Pagination,
  Navigation,
  Mousewheel,
  FreeMode,
  EffectFade,
} from "swiper";
import EmbellashesProductCard from "../components/EmbellashesProductCard";
import ServiceSliderHome from "../components/ServicesSliderHome";
import MobileNavbar from "../components/Accordion/MobileNavbar";

const MicroBlading = () => {

  const [products, setProducts] = useState([]);
  // getting products function

  const getProducts = async()=>{
    const products =await db.collection('Products').get();
    const productsArray =[];
    for(var snap of products.docs){
      var data = snap.data();
      data.ID =snap.id;
      productsArray.push({
        ...data
      })
      if(productsArray.length=== products.docs.length){
        setProducts(productsArray);
      }
    }
  }

  useEffect(() => {
   getProducts();
  
  }, [])
  return (
    <div className="bodyOfMicrobladingPage">
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
    <div className='MegaVolumeExtensionPageConatiner-openThroughNavbar-heading'>
    Our MicroBlading Services
   </div>
      <div className="MainMicroBladingProductPageContainerAlsoContaining2ndFold">
        <div className="MainMicroBladingProductPageContainer">
          <img
            className="MicroBladingProductPageImg"
            src={MicroBladingProductPageImg} 
          />
          <div className="MicroBladingProductPageInfo">
            <div className="MicroBladingProductPageHeading">
              Fresno Microblading Experts
            </div>

            <div className="MicroBladingProductPageInfoBelowHeading">
              <div className="MicroBladingProductPageInfoBelowHeading-firstLine">Redesign your eyebrows to create a more youthful or stylish look</div>
              <ul className="MicroBladingProductPageInfoBelowHeadingList">
                <li>Hair-like strokes mimic natural brows</li>
                <li>Lasts 1-2 years with one touch-up session</li>
                <li>No machines, no downtime</li>
              </ul>
              <div className="MicroBladingProductPageInfoBelowHeading-LastLine">Microblading is ideal for individuals who desire to enhance the
              appearance of their eyebrows.</div>
             
            </div>

            <div className="bookNowButtonMicroBladingPage">
              <BookNowButton />
            </div>
          </div>
        </div>
        <div className="WhatPeopleAreSayingContainer">
          <p className="whatPeopleAreSaying">
            Since your skin ages and trends change, you can redesign your
            eyebrows to create a more youthful or stylish look using
            microblading, which lasts up to two years. With no downtime, the
            procedure takes only an hour.
          </p>
        </div>
      </div>

      <div className="MainMicroBladingProductPage2ndContainer">
        <Swiper
          spaceBetween={30}
          direction={"vertical"}
          slidesPerView={3}
          // slidesPerGroup={1}
          loop={true}
          effect={"fade"}
          autoplay={{
            delay: 100000,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          mousewheel={false}
          freeMode={false}
          // navigation={true }
          modules={[
            Autoplay,
            Pagination,
            Navigation,
            Mousewheel,
            FreeMode,
            EffectFade,
          ]}
          className="mySwiper forMakingResponsivetheTestimonialForMicrobladingPage"
        >
          <SwiperSlide>
            <div className="testimonailWrapper">
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Great product line. Very attentive staff to deal with. Highly
                  recommend!"
                </p>
                <p className="personName">MICHELLE C.</p>
              </div>
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Embellashes by Jackie This was my first time getting lash
                  extensions and I will definitely be coming back! Jackie was
                  welcoming, painless and made the service very comfortable!"
                </p>
                <p className="personName">Amanda S</p>
              </div>

              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Very professional, organized, clean business. I am beyond
                  satisfied with the service I have received. I’ve been going to
                  Jackie since the beginning of this year. "
                </p>
                <p className="personName">JENNIFER O</p>
              </div>
            </div>
          </SwiperSlide>

        
          <SwiperSlide>
            <div className="testimonailWrapper">
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Jackie is amazing, I would recommend her to anyone! "
                </p>
                <p className="personName">KRISTI M</p>
              </div>
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Best service in town!! I absolutely love my eyelash look and
                  the products used! she is so sweet and provides such a relaxing
                  upscale environment. Fresno finally a technician that has the
                  quality and experience I’ve been looking for!!"
                </p>
                <p className="personName">JESSICA S</p>
              </div>
              <div className="personNameAndHisTestimonail bordered">
              <p className="testimonial">
                "I would give a 10 stars review if I could. Absolutely in love.
                Anousone gave me lashes that no strip lashes could ever do. I
                feel like a doll."
              </p>
              <p className="personName">KAY X</p>
            </div>
            </div>
          </SwiperSlide>
 
         {/*} <SwiperSlide>
            <div className="personNameAndHisTestimonail bordered">
              <p className="testimonial">
                "Amazing! I’m beyond happy with my teeth whitening. The salon
                was super cute and Katelyn was super nice! 5/5 experience!!!! "
              </p>
              <p className="personName">EMILY H.</p>
            </div>
        </SwiperSlide>*/}
          {/*<SwiperSlide>
<div className='personNameAndHisTestimonail bordered'>
<p className='testimonial'>"Almost want to keep this my little secret, but realize she must grow. I’ve been to several other lash salons in the area and have been repeatedly met with too thick, too long, too heavy extensions, causing soooo much damage! At first had clumpy, stuck together lashes that were hella wonky (someone even stacked lashes WTF), she put me on a rehab program for a natural healthier set and I couldn’t be more happy. Out with the old & in with the better, safer, and damage free lashes!"</p>
<p className='personName'>TINA H
</p>
</div>
</SwiperSlide>*/}
          <SwiperSlide>
          <div className="testimonailWrapper">
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Jackie did an amazing job. Especially with my lashes being a
                  little complicated she worked her magic! Thank you! Love them!
                  Highly recommended!"
                </p>
                <p className="personName">Lisa L</p>
              </div>
              <div className="personNameAndHisTestimonail bordered">
              <p className="testimonial">
                "Shelly makes my lashes look amazing every time!"
              </p>
              <p className="personName">SAM S.</p>
            </div>
            <div className="personNameAndHisTestimonail bordered">
            <p className="testimonial">
              "Does an amazing job! They look fabulous every time 🙂 she takes
              her time to make sure that you look your best when you leave. "
            </p>
            <p className="personName">TAZANNA H</p>
            </div>
          </div>
          </SwiperSlide>
     
          {/*<SwiperSlide>
<div className='personNameAndHisTestimonail bordered'>
<p className='testimonial'>"I can’t express how much I love Anousone and every experience I’ve had with her! She’s been doing my lashes since last year past 6 and has been so detailed with every lash application. She makes you feel so glamorous in such a cute studio setting! She’s one skilled professional artist! I’ve received an abundant amount of compliments! Thanks babe
"</p>
<p className='personName'>GABRIELA F</p>
</div>
</SwiperSlide>*/}
          {/*<SwiperSlide>
<div className='personNameAndHisTestimonail bordered'>
<p className='testimonial'>"OMG you will love this up scale salon. It’s really so pretty and inviting that you won’t want to leave and makes you feel like you can’t wait to come back to such a happy place. You will love it! EmbelLASHES by Anousone Well there’s only one word that I can use to explain my experience with Anousone “AWESOME”! I have very small Asian eyes & my lashes are very short but once Anousone put my lashes on, my eyes really popped, looked so much bigger & I didn’t look as tired. Just listening and talking with her is such a fun experience, she’s beautiful, personable, funny and such a great person to be around. Her energy is contagious and when you leave you will feel so happy & ready to go show off your new look.

"</p>
<p className='personName'>JOANN H</p>
</div>
</SwiperSlide>*/}
         
          <SwiperSlide>
          <div className="testimonailWrapper">
              <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "Venue Anousone does the BEST LASHES!!..always friendly, fun,
                  relaxing and private!! 100% comfortable and long lasting
                  application..they rarely ever fall off without outgrowth and are
                  never stuck to eachother!!…I have never been happier with my
                  lashes since i came to Anousone!! Make sure to book with her!!
                  if your going anywhere else your NOT LOVInG your lashes the way
                  you should!! "
                </p>
                <p className="personName">SAMANTHA S</p>
              </div>
              <div className="personNameAndHisTestimonail bordered">
              <p className="testimonial">
                "WoW.. Anousone was so professional, and just as sweet as could
                be. Her shop is Gorgeous and very comfortable. The bed was so
                relaxing and her touch was so gentle that i dozed off a few
                times. My lashes looked and felt amazing. She has Bella lash
                products that help maintain your new look, the product is
                wonderful. Anousone is not pushy about purchasing the product
                but does explain the way it works. Cant say enough about her
                services."
              </p>
              <p className="personName">SHAIE D</p>
            </div>
            <div className="personNameAndHisTestimonail bordered">
                <p className="testimonial">
                  "So glad and thankful that I found her??"
                </p>
                <p className="personName">Robin V</p>
              </div>
          </div>
          </SwiperSlide>
          
          
        </Swiper>

        <Swiper
          spaceBetween={30}
          loop={true}
          effect={"fade"}
          centeredSlides={false}
          autoplay={{
            delay: 2900,
            disableOnInteraction: true,
          }}
          // pagination={{
          //   clickable: true,
          // }}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation, EffectFade]}
          className="mySwiper"
        >
          <SwiperSlide>
            {" "}
            <img
              className="MicroBladingProductPage2ndImg"
              src={MicroBladingProductPage1stImgOfSlider}
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img
              className="MicroBladingProductPage2ndImg"
              src={MicroBladingProductPage2ndImgOfSlider} 
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img
              className="MicroBladingProductPage2ndImg"
              src={MicroBladingProductPage3rdImgOfSlider} 
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img
              className="MicroBladingProductPage2ndImg"
              src={MicroBladingProductPage4thImgOfSlider} 
            />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img
              className="MicroBladingProductPage2ndImg"
              src={MicroBladingProductPage6thImgOfSlider} 
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="ClientSelfieAnd2ndCallToAction">
        <img
          className="ClientSelfieImg"
          src={MicroBladingProductPage5thImgOfSlider} 
        />
        <div className="ClientSelfieBesideContent">
          {" "}
          Each client fully participates in creating their desired look, shape,
          and colour. Every procedure is unique because colours are
          custom-blended and custom-designed for each client.{" "}
        </div>
        <div className="bookNowButton2MicroBladingPage">
          <BookNowButton />
        </div>
      </div>
    
      {products.length > 0 &&(
        <div>
        <EmbellashesProductCard products={products}/>
       </div>
      )}
      {products.length<1 && (
        <div> please wait...</div>
      )}
      <ServiceSliderHome />
      <Footer />
    </div>
  );
};

export default MicroBlading;
