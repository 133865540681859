export const navItems=[
    {
        id:1,
        navItem1:'Virtual Try On',
        path:'/virtualEyeStudio'
      
    },
    {
        id:2,
        navItem1:'Products',
        navItem1Child:[
            {
                navItem1:'Emebllash Products',
             
            },
            {
                navItem1:'Bellalash Products'  
            }
        ]

    },
    {
        id:3,
        navItem1:'Services',
        navItem1Child:[
            {
                navItem1:'Eyelash Extension',
                path:'/AllProducts',
                navItem1Child1:[
                        {
                            navItem2:'Kertain lash Lift',
                            path:'/KeratinLashLiftPage'
                        },
                        {
                            navItem2:'Classics',
                            path:'/ClassicsExtenionPage'
                        },
                        {
                            navItem2:'Hybrids',
                            path:'/HybridsExtenionPage'
                        },
                        {
                            navItem2:'Volume',
                            path:'/VolumeLashExtenionPage'

                        },
                        {
                            navItem2:'Mega Volume',
                            path:'/MegaVolumeLashExtenionPage'
                        },
                ]
            },
            {
                navItem1:'Eyebrows',
                navItem1Child1:[
                    {
                        navItem2:"Microblading",
                        path:'/bookNowForMicroBladingService'
                    },
                    {
                        navItem2:"Brows By Katelyn",
                        path:'/browsByKatelynpage'
                    },
                ]
            },
            {
                navItem1:'Waxing',
                path:'/Waxing'
            },
            {
                navItem1:'Removal',
                path:'/Removal'
            },
            {
                navItem1:'SmileTherapy'
            }

        ]

    },
    {
        id:4,
        navItem1:'Courses',
        path:'/courses'
      
    },
    {
        id:5,
        navItem1:'Artists',
        path:'/artists'
     
    },
    {
        id:6,
        navItem1:'Your Cart',
        path:'/checkout'
     
    },
    {
        id:7,
        navItem1:'Sign In',
        path:'/login'
   
    },
]