
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../../images/hybridLashFillJackie.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Annosune Services/HybridsByAnnosune.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const HybridsByannosune = () => {
  return (
    <div className='HybridsByannosuneContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$85.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEK HYBRID LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$130.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEK HYBRID LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default HybridsByannosune