
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from '../../images/KeratinlashLiftService.jpg'
import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const ClassicsByannosune = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerForNavbar'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImg" src={KeratinLashLiftServiceImg}/>
    <div className="KeratinLashLiftForNavbarInfoClassics">
    <div className="KeratinLashLiftForNavbarPriceKeratinLashLift">$85.00</div>
    <div className="KeratinLashLiftForNavbarHeadingKrtainLashLift">Keratin Eyelash Lift</div>
    <div className="KeratinLashLiftForNavbarContent">Keratin Lash Lift, a treatment designed to darken and lift lashes without adding false strands. "With this procedure your natural lashes are lifted up and filled with a special pigment infusion," with this result you have beautiful natural lashes that curve upwards. 1hr service
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
    <button className="buyNowKeratinLashLiftForNavbarBtnKeratinlashLift1">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImg" src={InLeiLashLiftAndFillersImg}/>
    <div className="KeratinLashLiftForNavbarInfoClassics">
    <div className="KeratinLashLiftForNavbarPriceKeratinLashLift">$110.00</div>
    <div className="KeratinLashLiftForNavbarHeadingKrtainLashLift">InLei Eyelash Lift + Fillers</div>
    <div className="KeratinLashLiftForNavbarContent">Lash Filler is a treatment, that will not only lift your lashes and add them a curl, but will also improve the condition and health of your natural lashes, it is the first treatment in the world, that by its clinical test has proven that increases the thickness of natural lashes by 24%! 1hr 15min service
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
    <button className="buyNowKeratinLashLiftForNavbarBtnKertainLashLift2 ">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default ClassicsByannosune