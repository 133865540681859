
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from '../../images/KeratinlashLiftService.jpg'
import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'
import AnnosuneImg from '../../images/AnnosuneImageWithBg.png'
import Jackie from "../../images/jackie.png";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const AnnosuneNForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerArtistsJackie'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImgArtists" src={Jackie}/>
    <div className="KeratinLashLiftForNavbarInfoAnnosune">
    <div className="KeratinLashLiftForNavbarHeadingArtists">   Jackie A</div>
    <div className="KeratinLashLiftForNavbarPrice">     (559) 530-0309</div>
    <div className="KeratinLashLiftForNavbarContentArtists">   I am a Licensed Cosmetologist and Certified Embellashes Stylist.
    I’m passionate about my work and educating my clients about their
    lashes is a must! I love to make everyone feel and look beautiful
    when they open their eyes and see themselves in the mirror.
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnArtistsJackie">
    <button className="buyNowKeratinLashLiftForNavbarBtnArtists"> Schedule An Appointment Now!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default AnnosuneNForNavbar