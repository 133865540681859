import React,{useState,useEffect} from 'react'
import { db } from '../Firebase';
import AllemeblashProductsCard from './AllEmbellashProductsCard'
const AllemeblashProductsPage = () => {
  const [products, setProducts] = useState([]);
  const getProducts = async()=>{
    const products =await db.collection('EmbellashProducts').get();
    const productsArray =[];
    for(var snap of products.docs){
      var data = snap.data();
      data.ID =snap.id;
      productsArray.push({
        ...data
      })
      if(productsArray.length=== products.docs.length){
        setProducts(productsArray);
      }
    }
    // console.log(productsArray,"hi lolu");
  }

  useEffect(() => {
    getProducts();
   
   }, [])

  return (
    <div>
    <div>
    {products.length > 0 &&(
   <div>
   <AllemeblashProductsCard products={products} />
  </div>
 )}
 {products.length<1 && (
   <div> please wait...</div>
 )}
   </div>
</div>
  )
}

export default AllemeblashProductsPage