import React from 'react'

const ScheduleAnAppointmentNowWithAnnosune = () => {
  return (
    <div className='scheduleAnAppointmentNowWithAnnosuneContainer'>
    <div className='scheduleAnAppointmentNowWithAnnosuneHeading'>
    Looking for a unique and custom service?
    <br></br> 
    Checkout the services below!
    </div>
    <div className='ClassicsByAnnosuneHeading'>
    Classics By Annosune</div>
    
    </div>
  )
}

export default ScheduleAnAppointmentNowWithAnnosune