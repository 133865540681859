import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import MicroBladingProductPageImg from "../../images/microbladingPageNewImg.jpg";
import ClasiicLashFullLiftShellyImg from '../../images/ClassicsBySheelyImg.jpg'
import HybridsLashFullLiftAnnosuneImg from '../../images/HybridsByAnnosuneImg.jpg'
import HybridsLashFullLiftHappyImg from '../../images/HybridsBYHappyimg.jpg'
import  HybridsLashFullLiftJackieImg from '../../images/hybridLashFillJackie.jpg'
import HybridsLashFullLiftShellyImg from '../../images/HybridsBySheelyImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerMicroblading">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatiner">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={MicroBladingProductPageImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoMicroblading">
              <div className="KeratinLashLiftForNavbarPrice"></div>
              <div className="KeratinLashLiftForNavbarHeadingforMicroblading-AligingIncenter">
              Fresno Microblading Experts
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Redesign your eyebrows to create a more youthful or stylish look<br/><br/>
             
                Hair-like strokes mimic natural brows<br/><br></br>
                Lasts 1-2 years with one touch-up session<br/><br/>
                No machines, no downtime<br/><br/>
              
              Microblading is ideal for individuals who desire to enhance the
              appearance of their eyebrows.
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/bookNowForMicroBladingService'>
                <button className="buyNowKeratinLashLiftForNavbarBtnMicroblading">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
       
      </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
