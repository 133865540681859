
import Navbar from '../components/Navbar'
import { useEffect } from 'react'
import EmbellashesPrductCard from '../components/EmbellashesProductCard'
import NewProducts from '../components/NewProducts'
import Artists from '../components/Artist'
import OurBlog from '../components/OurBlog'
import Footer from '../components/Footer'
import EyelashHome2 from '../components/Component1Slider1Home'
import EyeBrowExtensionHome2 from '../components/Component1Slider2Home'
import EyelashAndBrowHome3 from '../components/EyelashAndBrowHome3'
import Component1Slider4Home from '../components/Component1Slider4Home'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
// import required modules
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper";
import './Homepage.css'
import { auth,db } from '../Firebase'
import CoursesSwiperHome from '../components/CoursesSwiperHome'
import ServiceSliderHome from '../components/ServicesSliderHome'
import Products from '../components/products/embellash Products/Products'
import MobileNavbar from '../components/Accordion/MobileNavbar'
const Homepage = () => {
const navigate =useNavigate();
  const [products, setProducts] = useState([]);
  const [artists, setArtists] = useState([]);
  const mobileNavbar = document.querySelector(
    ".mobileNavbarDivForHomepage"
  );
  const navMenu = document.querySelector(".main-div");

  if (navMenu) {
    mobileNavbar.style.position = "static";
  }
  // console.log(navMenu,"hiiiiii");
//  getting current user uid
function GetUserUid(){
  const [userUid,setUserUid]=useState(null);
  useEffect(()=>{
    auth.onAuthStateChanged(user=>{
      if(user){
        setUserUid(user.uid);
      }
    })
  },[])
  return userUid;
}

const userUid= GetUserUid();
// console.log(userUid);
// const addToCart=(products)=>{
// if(userUid==null){
//   // console.log(products);
// }
// else{
// navigate('/login');
// }

  // getting current user function
  function GetCurrentUser(){
    const [userName, setUserName] = useState(null);
    useEffect(()=>{
      auth.onAuthStateChanged(userName=>{
        if(userName){
          db.collection('NewAccount').doc(userName.uid).get().then(snapshot=>{
            setUserName(snapshot.data().FullName);
          })
        }
        else{
          setUserName(null);
        }
      })
    },[])
    return userName;
  }
  const users= GetCurrentUser();
  // console.log(users);

  // getting products function

  const getProducts = async()=>{
    const products =await db.collection('Products').get();
    const productsArray =[];
    for(var snap of products.docs){
      var data = snap.data();
      data.ID =snap.id;
      productsArray.push({
        ...data
      })
      if(productsArray.length=== products.docs.length){
        setProducts(productsArray);
      }
    }
  }
  const getArtists = async()=>{
    const artists =await db.collection('artists').get();
    const artistsArray =[];
    for(var snap of artists.docs){
      var data = snap.data();
      data.ID =snap.id;
      artistsArray.push({
        ...data
      })
      if(artistsArray.length=== artists.docs.length){
        setArtists(artistsArray);
      }
    }
  }

  useEffect(() => {
   getArtists();
  
  }, [])
  useEffect(() => {
    getProducts();
   
   }, [])
  


  return (

    

    <div className='BodyOfHomepage'>
  <div className='NavBarPositionOnHomepage'><Navbar users={users}/></div>
   <div className='mobileNavbarDivForHomepage'> <MobileNavbar /></div>
    <Swiper
    
    spaceBetween={300}
    centeredSlides={true}
    loop={true}
    effect={'fade'}
    autoplay={{
      delay: 1000000,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation,EffectFade]}
    className="mySwiper forHeightInResponsive"
  >
  <SwiperSlide className='forHeightInResponsive'><EyeBrowExtensionHome2/></SwiperSlide>

    <SwiperSlide><EyelashHome2/></SwiperSlide>

    
    <SwiperSlide><Component1Slider4Home/></SwiperSlide>

    {/* <SwiperSlide><SmileTherapyHome2/></SwiperSlide>*/}
    
  </Swiper>
 
  <ServiceSliderHome/>
  <CoursesSwiperHome/>
  
  {products.length > 0 &&(
    <div>
    <EmbellashesPrductCard products={products} />
   </div>
  )}
  {products.length<1 && (
    <div> please wait...</div>
  )}
  {artists.length > 0 &&(
    <div>

  <Artists artists={artists}/>
   </div>
  )}
  {artists.length<1 && (
    <div> please wait...</div>
  )}
  <OurBlog/>
  <Footer/>
</div>
 


   
  )
}

export default Homepage