import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import './ServicesSliderHome.css'

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import KertainLashLiftService from '../components/Services/KeratinLashLiftService'
import InLeiEyelashLiftAndFillers from '../components/Services/InLeiEyelashLiftAndFillers'
import UpperLipWaxing from '../components/Services/UpperLipWaxing'
import SideburnsWaxingService from '../components/Services/SideburnsWaxingService'
import ServicesApi from './ServicesApi'
import AllServices from "./AllServices";


const ServiceSliderHome = () => {
  return (
    
  <>
<div className='allThingsContainerServiceSlider' >
<h1 className='headingOurServices'> Book Your Services Now</h1>

  <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
      {ServicesApi.map((currElm)=>{
        const {id} = currElm;
        // console.log(currElm,"hhhhhhhhhhhhhhhhhhhhhhhhh");
        return(
        <SwiperSlide ><AllServices key={id} {...currElm} /></SwiperSlide>
        )
      })}
      
        
     
        
      </Swiper>




{/*<div className='AdhesiveNozzlewipes'><AdhesiveNozzlewipes/></div>
<div className='DisposableApplicator'><DisposableApplicator/></div>

<div className='FanAddicts'><FanAddicts/></div>
*/}


</div>
</>
  )
}

export default ServiceSliderHome