
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import MicroBladeShadeImg from '../../images/MicrobladePlusShadeImg.jpg'
import EyebrowTouchUp from '../../images/Eyebrow-Touch-Up.jpg'
import FrecklesImg from '../../images/FrecklesImg.jpg'
import HennaEyebrowsiMg from '../../images/HennaEyebrowsiMg.jpg'
import eyebrowLamination from '../../images/eyebrowLamination.jpg'
import angelRemovalImg from '../../images/angelRemovalImg.jpg'
import foreignPartialFillImg from '../../images/foreignPartialFillImg.jpg'
import katelynMicrobladingForeignTouchupImg from '../../images/katelynMicrobladingForeignTouchupImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Annosune Services/ClassicsByAnnosune.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const ClassicsByannosune = () => {
  return (
    <div className='ClassicsByannosuneContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={MicroBladeShadeImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$500.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">MICROBLADE + SHADE</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Microblading is a type of semi-permanent tattooing that creates the illusion of fuller, more defined eyebrows. Using a specialized pen, small amounts of pigment are deposited into the skin, mimicking the look of natural hairs.<br></br><br></br> The results typically last 1-2 years with one touch up session. This technique is ideal for anyone wanting to enhance their appearance without having to commit to long-term makeup.
    <br></br><br></br>
    ** 50% non refundable deposit for appointment
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={EyebrowTouchUp}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$250.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">Eyebrow Touch Up</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Must be confirmed with Katelyn. Fill for reoccurring Microblade + Shade clients
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={FrecklesImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$100.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">Freckles</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Looking for a way to add some personality to your look? Freckle tattooing might be the perfect solution! A makeup artist can insert pigment in your cheeks and nose to give you a freckled appearance that is totally unique.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={eyebrowLamination}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$150.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">Henna Eyebrows</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Looking for a way to add some color to your look?In this procedure more definition is given to eyebrows by adding color to them.



    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={angelRemovalImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$150.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">Eyebrow Lamination</div>
   <div className="ClasiicLashFullLiftAnnosuneContent"> 
   Enhancing brows for a fuller look.



   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={katelynMicrobladingForeignTouchupImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$155.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">Angel Removal</div>
   <div className="ClasiicLashFullLiftAnnosuneContent"> 
   Are you looking to get rid of your old permanent makeup? We can help! Our removal solution will have you back to wearing brow makeup in just 24 hours. The number of sessions required varies for each client, but we always aim for optimal results. 
   <br></br><br></br>**Please note that this treatment is only for eyebrows - not eyeliner or body tattoos.<br></br><br></br>** A 50% deposit is required to schedule your appointment. Reschedules are required within 48 hours of your appointment.



   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={foreignPartialFillImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$250.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">Foreign Partial fill</div>
   <div className="ClasiicLashFullLiftAnnosuneContent"> 
   For those in need of a Touch up, but have prior work from another Brow Artist. This would be a Foreign Fill/Partial Microblading procedure.<br></br><br></br> **Must be less than 50% of brow needed to microblade. Must be confirmed with stylist before booking.


   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
   

   </Swiper>
    </div>
  )
}

export default ClassicsByannosune