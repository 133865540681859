import { React, useRef, useState, useEffect } from "react";
import "./Navbar.css";
import { BsSearch } from "react-icons/bs";
import {
  AiOutlineShoppingCart,
  AiOutlineMenu,
  AiFillCaretRight,
} from "react-icons/ai";
import KeratinLashLiftContainerForNavbar from "../components/Components For Navbar/KeratinLashLiftComponentForNavbar";
import MicrobladingForNavbar from "../components/Components For Navbar/MicrobladingForNavbar";
import MegaVolumeForNavbar from "../components/Components For Navbar/MegaVolumeForNavbar";
import { IoIosArrowForward } from "react-icons/io";
import logoFooter from "../images/embellashesLogoFooter.png";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "./context/Context";
import { auth } from "../Firebase";
import EmbellashesProductCard from "./EmbellashesProductCard";
import e from "cors";
import ClassicsForNavbar from "./Components For Navbar/ClassicsForNavbar";
import HyrbridsFornavbar from "./Components For Navbar/HybridsFornavbar";
import VolumeForNavbar from "./Components For Navbar/VolumeforNavbar";
import WaxingForNavbar from "./Components For Navbar/WaxingFornavbar";
import BrowsBykatelynForNavbar from "./Components For Navbar/BrowsByKatelynForNavbar";
import RemovalForNavbar from "./Components For Navbar/RemovalForNavbar";
import ClassicLashCertificationcourseForNavbar from "./Components For Navbar/ClassicLashCertificationForNavbar";
import LashLiftCertificationcourseForNavbar from "./Components For Navbar/LashLiftCourseForNavbar";
import VolumeLashCertificationcourseForNavbar from "./Components For Navbar/VolumeLashCertifticationForNavbar";
import AnnosuneNForNavbar from "./Components For Navbar/AnnosuneNForNavbar";
import JackieAForNavbar from "./Components For Navbar/JackieForNavbar";
import KatelynNForNavbar from "./Components For Navbar/KatelynNFornavbar";
import ShellyCForNavbar from "./Components For Navbar/ShellyForNavbar";
import HappyVForNavbar from "./Components For Navbar/HappyForNavbar";
const Navbar = ({ users }) => {
  const navigate = useNavigate();
  const [showsignInSearchCart, setshowSignInSearchCart] = useState(false);
  const [isActive, setActive] = useState(false);
  const [eyelashExtensionOpen, setEyelashExtensionOpen] = useState(false);
  let menuRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current.contains(event.target)) {
        setEyelashExtensionOpen(false);
      }
    });
  }, [1000]);

  const [isEyelashExtensionActive, setEyelashExtensionActive] = useState(false);

  const [{ basket, user }, dispatch] = useStateValue();

  const [basket1, setBasKet] = useState(
    JSON.parse(localStorage.getItem("basketItem")) || []
  );
  // console.log(basket,"looooooooooooo");
  // console.log(basket1,"mooooooooooooo");

  useEffect(() => {
    setBasKet(JSON.parse(localStorage.getItem("basketItem")) || []);
  }, []);
  // const [toggleState,setToggleState]= useState(false);
  // const [toggleEyelashExtension,setToggleEyelashExtension]= useState(false);

  const toggleMenu = (e) => {
    e.target.classList.toggle("active");
    // setToggleState(!toggleState);
  };

  const handleAuthentication = () => {
    if (user) {
      auth.signOut().then(() => {
        navigate("/login");
      });
    }
  };
  return (
    <>
      <nav className="main-nav">
        {/*1st logo box in the main div */}
        <div className="logo">
          {/*<img src={LogoImg} className='logoImg'/>*/}

          <h1 className="companyName">
            <Link className="menu-link-CompanyName" to="/">
              <img src={logoFooter} className="companyNameImg" />
            </Link>
          </h1>
        </div>
        {/*2nd menu box in the main div */}
        <div
          className={
            showsignInSearchCart ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li className="toAddListStyleNone">
              <Link className="menu-link-Link" to="/virtualEyeStudio">
                Virtual Try On
              </Link>
            </li>
            <li className="toAddListStyleNone productsMenu">
              <Link className="menu-link-Link " to="/AllProducts">
                Products
                <IoIosArrowForward className="rightArrowIcon" />
              </Link>
              <div className="dropDownMenu">
                <ul>
                  <li className="emebellashProductsHover">
                    <Link className="menu-link-Link" to="/AllEmbellashProducts">
                      {" "}
                      Embellash Products
                    </Link>
                  </li>
                  <div className="ourProductsForNavbar">
                    <EmbellashesProductCard />
                  </div>
                  <li>Bellalash Products</li>
                </ul>
              </div>
            </li>

            <li
              onClick={toggleMenu}
              className={`toAddListStyleNone productsMenu `}
            >
              <Link to="#" className="menu-link-Link">
                {" "}
                Services <IoIosArrowForward className="rightArrowIcon" />
              </Link>

              <div className="dropDownMenu2">
                <ul className="ListOfDropDownMenuServices">
                  <li className={`eyelashExtensionPointer `}>
                    {" "}
                    Eyelash Extension
                    <div className="EyelashExtensionServicesDropDown">
                      <ul className="EyelashExtensionServicesDropDownList">
                        <li className="eyelashEtensionKeratinLashLiftList">
                          <Link to="/KeratinLashLiftPage">
                            Keratin Lash Lift{" "}
                          </Link>{" "}
                          <div className="toHideKeratinLashLiftContainerForNavbar">
                            <KeratinLashLiftContainerForNavbar />
                          </div>
                        </li>
                        <li className="eyelashEtensionForClassics">
                          <Link to="/ClassicsExtenionPage">Classics</Link>{" "}
                          <div className="toHideClassicsContainerForNavbar">
                            <ClassicsForNavbar />
                          </div>
                        </li>
                        <li className="eyelashEtensionForHybrids">
                          <Link to="/HybridsExtenionPage">Hybrids</Link>{" "}
                          <div className="toHideHybridsContainerForNavbar">
                            <HyrbridsFornavbar />
                          </div>
                        </li>
                        <li className="eyelashEtensionForVolume">
                          <Link to="/VolumeLashExtenionPage">Volume</Link>{" "}
                          <div className="toHideVolumeContainerForNavbar">
                            <VolumeForNavbar />
                          </div>
                        </li>
                        <li className="eyelashEtensionForMegaVolume">
                          <Link to="/MegaVolumeLashExtenionPage">
                            {" "}
                            MegaVolume
                          </Link>{" "}
                          <div className="toHideMegaVolumeContainerForNavbar">
                            <MegaVolumeForNavbar />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className={`eyebrowsServicePointer`}>
                    Eyebrows <IoIosArrowForward className="rightArrowIcon" />
                    <div className="EyebrowsServicesDropDown">
                      <ul className="ListOfDropDownMenuServicesEyebrows">
                        <li className="MicrobladingServiceList">
                          <Link to="/bookNowForMicroBladingService">
                            {" "}
                            MicroBlading
                          </Link>
                          <div className="toHideMicrobladingContainerForNavbar">
                            <MicrobladingForNavbar />
                          </div>
                        </li>

                        <li className="browsBykaetlynServiceList">
                          <Link to="/browsByKatelynpage">Brows By Katelyn</Link>
                          <div className="toHideBrowsBykatelynContainerForNavbar">
                            <BrowsBykatelynForNavbar />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="WaxingServiceList">
                    <Link to="/Waxing"> Waxing</Link>
                    <div className="toHideWaxingContainerForNavbar">
                      <WaxingForNavbar />
                    </div>
                  </li>
                  <li className="RemovalServiceList">
                    <Link to="/Removal"> Removal</Link>
                    <div className="toHideRemovalContainerForNavbar">
                      <RemovalForNavbar />
                    </div>
                  </li>
                  <li>
                    Smile Therapy{" "}
                    <IoIosArrowForward className="rightArrowIcon" />
                  </li>
                </ul>
              </div>
            </li>

            <li
              className="toAddListStyleNone productsMenu "
              onClick={(e) => setActive(!isActive)}
            >
              <Link className="menu-link-Link" to="/courses">
                Courses
                <IoIosArrowForward className="rightArrowIcon" />
              </Link>
              <div className="dropDownMenuCourses">
                <ul className="listOfDropdownMenuCourses">
                  <li className="classicLashCertificationList">
                    <Link to="/courses">Classic Lash Certification</Link>
                    <div className="toHideClassicLashCertificationForNavbar">
                      <ClassicLashCertificationcourseForNavbar />
                    </div>
                  </li>
                  <li className="LashLiftCertificationList">
                    <Link to="/courses"> Lash Lift/Tint Course</Link>
                    <div className="toHideLashLiftCertificationForNavbar">
                      <LashLiftCertificationcourseForNavbar />
                    </div>
                  </li>
                  <li className="VolumeLashCertificationList">
                    <Link to="/courses">Volume Lash Certification</Link>
                    <div className="toHideVolumeLashCertificationForNavbar">
                      <VolumeLashCertificationcourseForNavbar />
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="toAddListStyleNone productsMenu">
              <Link className="menu-link-Link" to="/artists">
                Artists <IoIosArrowForward className="rightArrowIcon" />
              </Link>
              <div className="dropDownMenuArtists">
                <ul className="listOfDropdownMenuArtists">
                  <li className="ArtistsAnmosuneNList">
                    <Link to="/artists"> Annosune N</Link>
                    <div className="toHideAnnosuneNForNavbar">
                      <AnnosuneNForNavbar />
                    </div>
                  </li>
                  <li className="ArtistsjackieAList">
                    <Link to="/artists"> Jackie A</Link>
                    <div className="toHideJackieAForNavbar">
                      <JackieAForNavbar />
                    </div>
                  </li>
                  <li className="ArtistsKatelynNList">
                    <Link to="/artists"> Katelyn N</Link>
                    <div className="toHideKatelynNForNavbar">
                      <KatelynNForNavbar />
                    </div>
                  </li>
                  <li className="ArtistsShellyCList">
                    <Link to="/artists"> Shelly C</Link>
                    <div className="toHideShellyCForNavbar">
                      <ShellyCForNavbar />
                    </div>
                  </li>
                  <li className="ArtistsHappyVList">
                    <Link to="/artists"> Happy V</Link>
                    <div className="toHideHappyVForNavbar">
                      <HappyVForNavbar />
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        {/*3rd signIn box in the main div */}
        <div className="signIn-search-cart">
          <ul className="signIn-search-cart-Desktop">
            <li className="toAddListStyleNone">Welcome {users}</li>
            <li className="shoppingCart productsMenu">
              <Link to="checkout" className="checkoutCart">
                {" "}
                <AiOutlineShoppingCart className="cart" />
                { basket.length}
              </Link>
            </li>

            <li className="signIn">
              <Link to={!user && "/login"} className="signInHeading">
                <div onClick={handleAuthentication}>
                  <span>{user ? "Sign Out" : "Sign In"}</span>
                </div>
              </Link>
            </li>
          </ul>
          {/* Hmaburger menu for making it responsive */}
          <div className="hamburger-menu">
            <a
              href="#"
              onClick={() => setshowSignInSearchCart(!showsignInSearchCart)}
            >
              <AiOutlineMenu />
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
