import React from 'react'
import newSlider1ImgRemovebgPreview from '../images/Screenshot_2022-06-30_141658-removebg-preview.png'
import './Component1Slider4Home.css'
import BookYourSlots from './Buttons/BookYourSlots'
import { Link } from 'react-router-dom'

const EyelashHome2 = () => {
  return (
    <>
  <div className='CoursesMainEverythingContainer'>
  <div className='CoursesMainSection'>
  <div className='CoursesMainSectionLine1'>Training That</div>
  <div className='CoursesMainSectionLine2'>gives you an edge</div>
  <Link to='/courses'><button className='checkoutOurcoursesNowButtonSlider3homepage'>Checkout Our Courses Now</button></Link>
  
  </div>
  <img src={newSlider1ImgRemovebgPreview} className='CoursesMainImgContainer'/>

  </div> 
    </>
  )
}

export default EyelashHome2