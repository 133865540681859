import React from 'react'
import eyeBrowServiceImg from '../images/eyebrowsSectionImg.jpg'
import './EyeBrowservices2.css'
import BookNowButtonForMicrobladingService from '../components/Buttons/BookNowButtonForMicrobladingService'

const EyeLashServices2 = () => {
  return (
    <div className='mainFlexEyeBrowServiceOfHomePageContainer'>
    <div className='mainflexEyeBrowServiceOfHomePageContainerHeading'>
    <p className='mainflexEyeBrowServiceOfHomePageContainerHeading1'>Eyebrow Services </p>
  
    <p className='mainflexEyeBrowServiceOfHomePageContainerHeading2' >Embellashes focuses on Eyebrow Microblading. </p>
   <p className='mainflexEyeBrowServiceOfHomePageContainerHeading3'>With that we also provide services like </p>
    
    <p className='mainflexEyeBrowContainerServiceOfHomePageHeading2ndPart'> Henna Brows, Eyebrow Lamination and many more!</p>
   
  <p className='mainflexEyeBrowContainerServiceOfHomePageHeading5'>Book your slots now!</p>
  <BookNowButtonForMicrobladingService/>
  </div>
  <img className='rightImgEyeBrowServiceOfHomePageFlexContainer' src={eyeBrowServiceImg}/>
    
    </div>
  )
}

export default EyeLashServices2