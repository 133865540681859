import React,{ useState }  from 'react'
import './Login.css'
import { Link, useNavigate } from 'react-router-dom'
import logoFooter from '../images/embellashesLogoFooter.png'
import { auth, db } from '../Firebase'

const Login = () => {
const navigate = useNavigate();
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const [successMsg,   setSuccessMsg] = useState('');

const SignIn = e=>{
e.preventDefault();

// somefancy firebase login shitttt...
    auth
        .signInWithEmailAndPassword(email,password)
        
            .then(()=>{
                setSuccessMsg('Logged In successfully')
              
                setEmail('');
                setPassword('');
                setTimeout(()=>{
                    setSuccessMsg('');
                    navigate('/');

                },3000)
            }).catch(e=> alert(e.message))

}


    return (
        <div className='bodyOfLoginPage'>
        <div className='cardOfLoginPage'>
        <img src={logoFooter}  className="logoImageOfSignIn"/>
            <div className='LoginPageHeading'>
                Sign-In
            </div>
            {successMsg &&<div>
                <div className="success-msg">{successMsg}</div>
               
                </div>}
            <form>
                 <h1 className='emailHeading'>Email</h1>
                 <input type='text' value={email}  onChange={e=> setEmail(e.target.value)} className='emailInput' placeholder='Enter your Email'/>
                 <h1 className='emailHeading'>Password</h1>
                 <input type='password' value={password}  onChange={e=> setPassword(e.target.value)}  className='emailInput' placeholder='Enter your Email'/>
            
                 <div className="SignInButton">
                 <Link  to='/signedIn'>
                 <button className='SignInButtonProcess' onClick={SignIn} type='submit'>Sign In</button>
                 </Link>
                 </div>
                 <div className="SignInButton">
                 <Link to='/CreateAnAccount'>
                 <button className='CreateNewAccountProcess' type='submit'>Create A new Account</button>
                 </Link>
                 </div>
            </form>
        </div>
    
    </div>
  )
}

export default Login