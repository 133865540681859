import React from 'react'
import './EyelashExtensionServiceComponent.css'
import BookNowButton from '../components/Buttons/BookNowButton'
import eyelashSectionImg from '../images/eyelashExtensionImg.jpg'

const EyeLashExtensionServiceComponent = () => {
  return (
    <div className='EyelashExtensionServicesMainContainer'>
     <div className='eyelashSection'>
    <img className='eyelashSectionimage' src={eyelashSectionImg}/>
   <div className='eyelashSectionHeading'>
        <h1 className='eyelashSectionHeadingInfo'>
        Eyelash Extension</h1>
  
         <div className='eyelashSectionInfo'>Embellashes provides a variety of Eyelash Services. We can enhance your natural lashes with LASH LIFTs or we can add length and make your lashes</div> 
         <div className='lastLine'>fuller with EYELASH EXTENSIONS.</div>
          
         <BookNowButton/>
    </div>
     
    </div>
</div>
  )
}

export default EyeLashExtensionServiceComponent