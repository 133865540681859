
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";


import Happy from "../../images/HappyArtist.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const AnnosuneNForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerArtistsHappy'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImgArtists" src={Happy}/>
    <div className="KeratinLashLiftForNavbarInfoAnnosune">
    <div className="KeratinLashLiftForNavbarHeadingArtists">Happy V</div>
    <div className="KeratinLashLiftForNavbarPrice">     (559) 530-0309</div>
    <div className="KeratinLashLiftForNavbarContentArtists">      I am a Licensed esthetician and part time nurse! I'm an
    embellashes certified artist and have been lashing for 2 years
    now. I love learning new lash styles, tips and tricks! Which is
    why you can trust mw because I'm learning from the best!
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnArtistsJackie">
    <button className="buyNowKeratinLashLiftForNavbarBtnArtists"> Schedule An Appointment Now!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default AnnosuneNForNavbar