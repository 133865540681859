import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Annosune from "../images/AnnosuneImageWithBg.png";
import Happy from "../images/HappyArtist.png";
import Shelly from "../images/shellyArtist.png";
import Jackie from "../images/jackie.png";
import Katelyn from "../images/Katelyn.png";
import "./ArtistsPage2.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, Outlet, useParams } from "react-router-dom";
import Button from "../components/Buttons/BuyNow";
import ClassicsByannosune from "../components/Annosune Services/ClassicsByannosune";
import ClassicsByShelly from "../components/Shelly services/ClassicsbyShelly";
import ClassicsByJackie from "../components/Jackie Services/ClassicsByjackie";
import ClassicsByHappy from "../components/Happy services/ClasssicsByHappy";

import HybridsByannosune from "../components/Annosune Services/HybridsByAnnosune";
import HybridsByJackie from "../components/Jackie Services/HybridsbYJackie";
import HybridsByShelly from "../components/Shelly services/HybridsByShelly";
import HybridsByHappy from "../components/Happy services/HybridsBYHappy";

import VolumeByAnnosune from "../components/Annosune Services/VolumeByAnnosune";
import VolumeByJackie from "../components/Jackie Services/VolumeByJackie";
import BrowsBykatelynArtistPagee from "../components/Jackie Services/BrowsByKatelyn";

import MegaVolumeByAnnosune from "../components/Annosune Services/MegavolumeByAnnosune";
import MobileNavbar from "../components/Accordion/MobileNavbar";

const ArtistsPage2 = () => {
  const [isClassicsByAnnosuneActive, setisClassicsByAnnosuneActive] =
    useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isClassicsByJackieActive, setisClassicsByJackieActive] =
    useState(false);
  const [isClassicsByShellyActive, setisClassicsByShellyActive] =
    useState(false);
  const [isClassicsByHappyActive, setisClassicsByHappyActive] = useState(false);

  const [isBrowsByKatelynActive, setisBrowsByKatelynActive] = useState(false);

  const [isHybridsByAnnosuneActive, setisHybridsByAnnosuneActive] =
    useState(false);
  const [isHybridsByJackieActive, setisHybridsByJackieActive] = useState(false);
  const [isHybridsByShellyActive, setisHybridsByShellyActive] = useState(false);
  const [isHybridsByHappyActive, setisHybridsByHappyActive] = useState(false);

  const [isVolumeByAnnosuneActive, setisVolumeByAnnosuneActive] =
    useState(false);
  const [isVolumeByJackieActive, setisVolumeByJackieActive] = useState(false);

  const [isMegaVolumeByAnnosuneActive, setisMegaVolumeByAnnosuneActive] =
    useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current.contains(event.target)) {
        setisClassicsByAnnosuneActive(false);
      }
    });
  }, []);
  let menuRef = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (!menuRef.current.contains(event.target)) {
        setisHybridsByAnnosuneActive(false);
        setisVolumeByAnnosuneActive(false);
        setisMegaVolumeByAnnosuneActive(false);
        setisClassicsByJackieActive(false);
        setisHybridsByJackieActive(false);
        setisVolumeByJackieActive(false);
        setisBrowsByKatelynActive(false);
        setisClassicsByShellyActive(false);
        setisHybridsByShellyActive(false);
        setisHybridsByHappyActive(false);
        setisClassicsByHappyActive(false);
        setIsRendered(false);
      }
    });
  }, [1000]);

  return (
    <div className="ArtistsMainConatiner">
      <div className='NavBarPositionOnHomepage'><Navbar /></div>
      <div className="MobileNavbarArtistPage"><MobileNavbar/></div>
      <div className="ArtistsPageHeading">OUR ARTISTS</div>
      <div className="AnnosuneFoldContainer">
        <img className="AnnosuneImg1" src={Annosune} />
        <div className="AnnosuneInfo">
          <div className="AnnosuneHeading">Annosune N</div>

          <div className="AnnosuneInfoBelowHeading">
           <div className="annosunePhonenUmberArtistPage"> (559) 530-0309</div>
            <div className="AnnosuneInfoBelowPhoneNumber">
              I take pride in the highest of quality, control, and education,I
              am recognized by the world renowned, National Association of Lash
              Artists. Providing knowledge to my clients as well students is a
              must, Education is key! I am a/an *NALA educator *License
              cosmetologist, in the State of CA *Certified Bella Lash Artist.
              *Owner and Co-Founder of EmbelLashes Lashes & Brows I Love to make
              women look and feel more beautiful. Lashes enhance your natural
              beauty! Book an Appointment or Take a Class with me today!
            </div>
          </div>

          <div className="bookNowButtonForAnnosune">
            <Link to="/scheduleAnAppointmentNowWithAnnosune">
              <button className="bookNowButtonOnArtistsForAnnosune">
                Schedule An Appointment Now
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="AnnosuneServicesButtons"
        onClick={() => setIsRendered(true)}
      >
        <Link to="classicsByAnnosune">
          {" "}
          <button
            onClick={(e) =>
              setisClassicsByAnnosuneActive(!isClassicsByAnnosuneActive)
            }
            className="ClassicdByAnnosuneButtonOnArtistsPage"
          >
            Classics
          </button>
        </Link>

        <div className="AnnosuneVolumeServicesButtons">
          <Link to="hybridsByAnnosune">
            <button
              onClick={(e) =>
                setisHybridsByAnnosuneActive(!isHybridsByAnnosuneActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Hybrids
            </button>
          </Link>
        </div>
        <div className="AnnosuneVolumeServicesButtons">
          <Link to="VolumeByAnnosune">
            <button
              onClick={(e) =>
                setisVolumeByAnnosuneActive(!isVolumeByAnnosuneActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Volume
            </button>
          </Link>
        </div>
        <div className="AnnosuneMegaVolumeServicesButtons">
          <Link to="MegaVolumeByAnnosune">
            <button
              onClick={(e) =>
                setisMegaVolumeByAnnosuneActive(!isMegaVolumeByAnnosuneActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Mega Volume
            </button>
          </Link>
        </div>
      </div>

      <div
     
        
      > {isClassicsByAnnosuneActive && (
          <div ref={menuRef}>
          <ClassicsByannosune />
          </div>
        )} 
        {isHybridsByAnnosuneActive && (
          <div ref={menuRef}>
          <HybridsByannosune />
          </div>
        )}
        {isVolumeByAnnosuneActive && (
          <div ref={menuRef}>
            <VolumeByAnnosune />
          </div>
        )}
        {isMegaVolumeByAnnosuneActive && (
          <div ref={menuRef}>
            <MegaVolumeByAnnosune />
          </div>
        )}
      </div>

      {/**/}
      <div className="AnnosuneFoldContainer">
        <img className="AnnosuneImg1" src={Jackie} />
        <div className="AnnosuneInfo">
          <div className="AnnosuneHeading">Jackie A</div>

          <div className="AnnosuneInfoBelowHeading">
            (559) 530-0309
            <div className="AnnosuneInfoBelowPhoneNumber">
              I am a Licensed Cosmetologist and Certified Embellashes Stylist.
              I’m passionate about my work and educating my clients about their
              lashes is a must! I love to make everyone feel and look beautiful
              when they open their eyes and see themselves in the mirror.
            </div>
          </div>

          <div className="bookNowButtonForAnnosune">
            <a
              href="https://www.vagaro.com/embellashes/book-now"
              target="_blank"
            >
              <button className="bookNowButtonOnArtistsForAnnosune">
                Schedule An Appointment Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="AnnosuneServicesButtons">
        <Link to="ClassicsByJackie">
          {" "}
          <button
            onClick={(e) =>
              setisClassicsByJackieActive(!isClassicsByJackieActive)
            }
            className="ClassicdByAnnosuneButtonOnArtistsPage"
          >
            Classics
          </button>
        </Link>

        <div className="AnnosuneVolumeServicesButtons">
          <Link to="HybridsByjackie">
            <button
              onClick={(e) =>
                setisHybridsByJackieActive(!isHybridsByJackieActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Hybrids
            </button>
          </Link>
        </div>
        <div className="AnnosuneVolumeServicesButtons">
          <Link to="VolumeByJackie">
            <button
              onClick={(e) =>
                setisVolumeByJackieActive(!isVolumeByJackieActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Volume
            </button>
          </Link>
        </div>
      </div>
      {isClassicsByJackieActive && (
        <div ref={menuRef}>
          <ClassicsByJackie />
        </div>
      )}
      {isHybridsByJackieActive && (
        <div ref={menuRef}>
          <HybridsByJackie />
        </div>
      )}
      {isVolumeByJackieActive && (
        <div ref={menuRef}>
          {" "}
          <VolumeByJackie />
        </div>
      )}
      <div className="AnnosuneFoldContainer">
        <img className="AnnosuneImg1" src={Katelyn} />
        <div className="AnnosuneInfo">
          <div className="AnnosuneHeading">Katelyn N</div>

          <div className="AnnosuneInfoBelowHeading">
            (559) 530-0309
            <div className="AnnosuneInfoBelowPhoneNumber">
              Other than Brows, Katelyn is a marketing manager and graphic
              designer. Her need for Another creative outlet, flexible hours &
              hobby, turned her to Microblading. She studied with the world
              reknowned BEAUTY ANGELS ACADEMY learning Semi permanent make up.
              She specializes in symmetry, using a golden ratio compass
              customizing each brow to your liking. If you are interested in
              Natural 3D like hair strokes, book your appointment with Katelyn
              today!
            </div>
          </div>

          <div className="bookNowButtonForAnnosune">
            <a
              href="https://www.vagaro.com/embellashes/book-now"
              target="_blank"
            >
              <button className="bookNowButtonOnArtistsForAnnosune">
                Schedule An Appointment Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="AnnosuneServicesButtons">
        <Link to="BrowsByKatelynOnArtistpage">
          {" "}
          <button
            onClick={(e) => setisBrowsByKatelynActive(!isBrowsByKatelynActive)}
            className="ClassicdByAnnosuneButtonOnArtistsPage"
          >
            BROWS BY KATELYN
          </button>
        </Link>
      </div>
      {isBrowsByKatelynActive && (
        <div ref={menuRef}>
          {" "}
          <BrowsBykatelynArtistPagee />
        </div>
      )}

      <div className="AnnosuneFoldContainer">
        <img className="AnnosuneImg1" src={Shelly} />
        <div className="AnnosuneInfo">
          <div className="AnnosuneHeading">Shelly C</div>

          <div className="AnnosuneInfoBelowHeading">
            (559) 530-0309
            <div className="AnnosuneInfoBelowPhoneNumber">
              I am a Licensed Esthetician and Certified Embellashes Artist, I
              have 7 years of experience in Lashing and extremely excited to be
              apart of the Embellashes Team
            </div>
          </div>

          <div className="bookNowButtonForAnnosune">
            <a
              href="https://www.vagaro.com/embellashes/book-now"
              target="_blank"
            >
              <button className="bookNowButtonOnArtistsForAnnosune">
                Schedule An Appointment Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="AnnosuneServicesButtons">
        <Link to="ClassicsByShelly">
          {" "}
          <button
            onClick={(e) =>
              setisClassicsByShellyActive(!isClassicsByShellyActive)
            }
            className="ClassicdByAnnosuneButtonOnArtistsPage"
          >
            Classics
          </button>
        </Link>

        <div className="AnnosuneVolumeServicesButtons">
          <Link to="HybridsByShelly">
            <button
              onClick={(e) =>
                setisHybridsByShellyActive(!isHybridsByShellyActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Hybrids
            </button>
          </Link>
        </div>
      </div>
      {isClassicsByShellyActive && (
        <div ref={menuRef}>
          <ClassicsByShelly />
        </div>
      )}
      {isHybridsByShellyActive && (
        <div ref={menuRef}>
          {" "}
          <HybridsByShelly />
        </div>
      )}
      <div className="AnnosuneFoldContainer">
        <img className="AnnosuneImg1" src={Happy} />
        <div className="AnnosuneInfo">
          <div className="AnnosuneHeading">Happy V</div>

          <div className="AnnosuneInfoBelowHeading">
            (559) 530-0309
            <div className="AnnosuneInfoBelowPhoneNumber">
              I am a Licensed esthetician and part time nurse! I'm an
              embellashes certified artist and have been lashing for 2 years
              now. I love learning new lash styles, tips and tricks! Which is
              why you can trust mw because I'm learning from the best!
            </div>
          </div>

          <div className="bookNowButtonForAnnosune">
            <a
              href="https://www.vagaro.com/embellashes/book-now"
              target="_blank"
            >
              <button className="bookNowButtonOnArtistsForAnnosune">
                Schedule An Appointment Now
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="AnnosuneServicesButtons forHappyGivingMargin">
        <Link to="ClassicsByHappy">
          {" "}
          <button
            onClick={(e) =>
              setisClassicsByHappyActive(!isClassicsByHappyActive)
            }
            className="ClassicdByAnnosuneButtonOnArtistsPage"
          >
            Classics
          </button>
        </Link>

        <div className="AnnosuneVolumeServicesButtons">
          <Link to="HybridsByHappy">
            <button
              onClick={(e) =>
                setisHybridsByHappyActive(!isHybridsByHappyActive)
              }
              className="ClassicdByAnnosuneButtonOnArtistsPage"
            >
              Hybrids
            </button>
          </Link>
        </div>
      </div>
      {isClassicsByHappyActive && (
        <div ref={menuRef}>
          <ClassicsByHappy />
        </div>
      )}
      {isHybridsByHappyActive && (
        <div ref={menuRef}>
          <HybridsByHappy />
        </div>
      )}

      <Footer />
    </div>
  );
};

export default ArtistsPage2;
