import React, { useRef,useEffect } from 'react'
import {FaceMesh} from '@mediapipe/face_mesh'
import * as Facemesh from '@mediapipe/face_mesh'
import * as cam from '@mediapipe/camera_utils'
import * as facemesh from "@tensorflow-models/facemesh"
import * as tf from  "@tensorflow/tfjs"
import WebCam from 'react-webcam'
import { drawMesh } from '../components/Utilities'

const VirtualTryOn = () => {
 {/*
  const webcamRef =useRef(null);
  const canvasRef =useRef(null);
var camera= null
const connect = window.drawConnectors
function onResults(results){
  // console.log(results,"results");
  // setting height and width
  canvasRef.current.width= webcamRef.current.video.videoWidth
  canvasRef.current.height=webcamRef.current.video.videoHeight

  const canvasElement = canvasRef.current;
  const canvasCtx = canvasElement.getContext("2d")
  canvasCtx.save()

  canvasCtx.clearRect(0,0,canvasElement.width,canvasElement.height)
  canvasCtx.drawImage(results.image,0,0,canvasElement.width,canvasElement.height)

  if(results.multiFaceLandMarks){
    for (const landmarks of results.multiFaceLandmarks) {
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_TESSELATION,
                     {color: '#C0C0C070', lineWidth: 1});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_RIGHT_EYE, {color: '#FF3030'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_RIGHT_EYEBROW, {color: '#FF3030'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_RIGHT_IRIS, {color: '#FF3030'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_LEFT_EYE, {color: '#30FF30'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_LEFT_EYEBROW, {color: '#30FF30'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_LEFT_IRIS, {color: '#30FF30'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_FACE_OVAL, {color: '#E0E0E0'});
      connect(canvasCtx, landmarks, FaceMesh.FACEMESH_LIPS, {color: '#E0E0E0'});
    }
  }

}
useEffect(()=>{
  const faceMesh= new FaceMesh({
    locateFile:(file)=>{
      return  `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
    }
  })

  faceMesh.setOptions({
    maxNumFaces:1,
    minDetectionConfidence:0.5,
    minTrackingConfidence:0.5,

  })

faceMesh.onResults(onResults);
  
  if(typeof webcamRef.current!=="undefined" && webcamRef.current!== null){
camera= new cam.Camera(webcamRef.current.video,{
  onFrame:async()=>{
    await faceMesh.send({image:webcamRef.current.video})
  },
  width:640,
  height:480,
});
camera.start()
  }
})
  return (
    <div>
    <WebCam 
    ref={webcamRef}
    style={{
        position:'absolute',
        marginRight:'auto',
        marginLeft:'auto',
        left:0,
        right:0,
        textAlign:'center',
        zIndex:9,
        width:640,
        height:480,
        
    
    }}>
    
    </WebCam>
    <canvas
    ref={canvasRef}
    style={{
      position:'absolute',
      marginRight:'auto',
      marginLeft:'auto',
      left:0,
      right:0,
      textAlign:'center',
      zIndex:9,
      width:640,
      height:480,
      
  
  }}>
    
</canvas>
    </div> */}

  const webcamRef =useRef(null);
  const canvasRef =useRef(null);
// load facemesh
const runFacemesh = async()=>{
  const net = await facemesh.load({
    inputResolution:{width:640,height:480},scale:0.8
  });
  setInterval(()=>{
    detect(net)
  },100)
}


// detect function
const detect =async(net)=>{
  if(
    typeof webcamRef.current !=="undefined" && 
    webcamRef.current !==null && 
    webcamRef.current.video.readyState===4
    ){
// get video properties
const video = webcamRef.current.video;
const videoWidth= webcamRef.current.video.videoWidth;
const videoHeight = webcamRef.current.video.videoHeight;
// set video width
webcamRef.current.video.width= videoWidth;
webcamRef.current.video.height= videoHeight;
// set canvas width
canvasRef.current.width= videoWidth;
canvasRef.current.height= videoHeight;
// make detections
const face = await net.estimateFaces(video)
console.log( face,"facesssssssssssssss");
// get cancavs context for drawing
const ctx = canvasRef.current.getContext("2d");
drawMesh(face,ctx)

    }

}

runFacemesh();
  return(
    <div className='FilterApp'>
    <header className='filterAppHeader'>
    <WebCam ref={webcamRef} style={
      {
        position:'absolute',
        marginRight:'auto',
        marginLeft:'auto',
        left:0,
        right:0,
        textAlign:'center',
        zIndex:9,
        width:640,
        height:480,
      }
    
    }/>
    <canvas ref={canvasRef} style={
      {
        position:'absolute',
        marginRight:'auto',
        marginLeft:'auto',
        left:0,
        right:0,
        textAlign:'center',
        zIndex:9,
        width:640,
        height:480,
    }
    }/>
    </header>
    </div>
  )

  
}

export default VirtualTryOn