import React from 'react'
import EyelashHomeImg from '../images/eyelashHomecomponent1Slider1b.jpg'
import './EyeLashHome2.css'
import BookYourSlots from './Buttons/BookYourSlots'

const EyelashHome2 = () => {
  return (
    <>
   {/* <div className='mainflexEyeLashContainerHeading'>
    <p className='mainflexEyeLashContainerHeading1'>MAINTAIN YOUR LEVEL HIGH  </p>
    <p className='mainflexEyeLashContainerHeading2ndPart'>and eyelashes long.</p>
    <p className='mainflexEyeLashContainerHeading2' >all day long</p>
    <p  className='mainflexEyeLashContainerHeading3'>Fresno's best eyelash extension service is what you need  </p>
    <p className='mainflexEyeLashContainerHeading4'> </p>

  <div className='buttonCSSEyelashesHome'><BookYourSlots/></div>
  </div>*/}
  <div className='ImgAndButtonContainer'>
  <img className='mainFlexEyeLashContainer' src={EyelashHomeImg} />
  <div className='mainFlexEyeLashContainerContent1'>Choose What's </div>
  <div className='mainFlexEyeLashContainerContent2'>Best For You </div>

  <div className='buttonForThisSlider'><BookYourSlots/></div>
  </div>  
    </>
  )
}

export default EyelashHome2