import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import './CoursesSwiperHome.css'
import ClassicLashCertificationcourse from "./ClassicLashCertificationcourse";
import VolumeLashExtensionCourse from "./VolumeLashExtensionCourse";
import LashLift_TintCourse from './LashLift_TintCourse'


   

const CoursesSwiperHome = () => {
  return (
    <div className="CourseSwiperContainer">
    <div className="HeadingOfCourseSwiper"> Courses Available</div>
    <Swiper
    spaceBetween={3000}
    centeredSlides={false}
    autoplay={{
      delay: 100000,
      disableOnInteraction: true,
    }}
  
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
  >
    <SwiperSlide><ClassicLashCertificationcourse/></SwiperSlide>
    <SwiperSlide><VolumeLashExtensionCourse/></SwiperSlide>
    <SwiperSlide><LashLift_TintCourse/></SwiperSlide>
  
    
  </Swiper>

    </div>
  )
}

export default CoursesSwiperHome