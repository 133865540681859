import "./App.css";
import { BrowserRouter, Route, Routes, Path } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Services from "./pages/Services";
import Courses from "./pages/Courses";
import ArtistsPage from "./pages/ArtistsPage";
import ArtistsPage2 from "./pages/ArtistsPage2";
import MicroBlading from "./pages/MicroBlading";
import Login from "./pages/Login";
import PaymentPage from "./components/PaymentPage";
import EyeLashExtensionServiceComponent from "./components/EyeLashExtensionServiceComponent";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import EyeLashExtensionServicePage from "./pages/EyelashExtensionServicesPage";
import { useEffect, useState } from "react";
import { auth } from "./Firebase";
import Checkout from "./components/Checkout";
import { useStateValue } from "./components/context/Context";
import AddArtsits from "./pages/AddArtists";
import AddProducts from "./pages/AddProducts";
import SignUp from "./pages/SignUp";
import ScheduleAnAppointmentNowWithAnnosune from "./components/Annosune Services/ScheduleAnAppointmentNowWithAnnosune";
import ClassicsByannosune from "./components/Annosune Services/ClassicsByannosune";
import HybridsByAnnosune from "./components/Annosune Services/HybridsByAnnosune";
import VolumeByAnnosune from "./components/Annosune Services/VolumeByAnnosune";
import MegaVolumeByAnnosune from "./components/Annosune Services/MegavolumeByAnnosune";
import ClassicsByjackie from "./components/Jackie Services/ClassicsByjackie";
import HybridsByjackie from "./components/Jackie Services/HybridsbYJackie";
import VolumeByJackie from "./components/Jackie Services/VolumeByJackie";
import BrowsByKatelyn from "./components/Jackie Services/BrowsByKatelyn";
import ClassicsByShelly from "./components/Shelly services/ClassicsbyShelly";
import HybridsByShelly from "./components/Shelly services/HybridsByShelly";
import ClassicsByHappy from "./components/Happy services/ClasssicsByHappy";
import HybridsByHappy from "./components/Happy services/HybridsBYHappy";
import KeratinLashLiftComponentOfNavbar from "./components/Components For Navbar/KeratinLashLiftComponentForNavbar";
import KeratinLashLiftServicePage from "./pages/KeratinlashLiftPage";
import WaxingPage from "./pages/WaxingPage";
import RemovalPage from "./pages/Removalpage";
import BrowsByKatelynPage from "./pages/BrowsByKatelyn";
import VirtualTryOn from "./pages/VirtualTryOn";
import SingleProductDesc from "./components/SingleProductDesc";
import VolumeEyelashextensionPage from "./pages/VolumeEyelashextensionPage";
import MegaVolumeExtensionPage from "./pages/MegaVolumeExtensionPage";
import HybridsExtensionPage from "./pages/HybridsExtensionPage";
import ClassicsExtensionpage from "./pages/ClassicsExtensionpage";
import ProductDesc from "./components/ProductDesc";
import AllProductsPage from "./pages/AllProductsPage";
import AddEmbellashProducts from './pages/AddEmbellashProducts'
import AllEmbellashProductsPage from './pages/AllemeblashProductsPage'
import AddServiceImages from './pages/AddServiceImages'
function App() {
  const [{user}, dispatch] = useStateValue();
  useEffect(() => {
    // this will only run once when app component loads..
    auth.onAuthStateChanged((authUser) => {
      // console.log("the user is>>>", authUser);

      if (authUser) {
        // the user just logged in/the user was logged in
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
      } else {
        // the user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        });
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/artists" element={<ArtistsPage2 />}>
          <Route path="classicsByAnnosune" element={<ClassicsByannosune />} />
          <Route path="hybridsByAnnosune" element={<HybridsByAnnosune />} />
          <Route path="VolumeByAnnosune" element={<VolumeByAnnosune />} />
          <Route
            path="MegaVolumeByAnnosune"
            element={<MegaVolumeByAnnosune />}
          />
          <Route path="ClassicsByJackie" element={<ClassicsByjackie />} />
          <Route path="HybridsByJackie" element={<HybridsByjackie />} />
          <Route path="VolumeByJackie" element={<VolumeByJackie />} />
          <Route
            path="BrowsByKatelynOnArtistpage"
            element={<BrowsByKatelyn />}
          />
          <Route path="ClassicsByShelly" element={<ClassicsByShelly />} />
          <Route path="HybridsByShelly" element={<HybridsByShelly />} />
          <Route path="ClassicsByHappy" element={<ClassicsByHappy />} />
          <Route path="HybridsByHappy" element={<HybridsByHappy />} />
        </Route>
        <Route
          path="/bookNowForMicroBladingService"
          element={<MicroBlading />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/browsByKatelynpage" element={<BrowsByKatelynPage />} />

 {/* <Route path="/AllProducts" element={<EyeLashExtensionServicePage />} />*/}
        <Route path="/AllProducts" element={<AllProductsPage />} />
        <Route path="/AllEmbellashProducts" element={<AllEmbellashProductsPage />} />

<Route path="/addEmbellashProducts" element={<AddEmbellashProducts/>}/>
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/addProducts" element={<AddProducts />} />
        <Route path="/virtualEyeStudio" element={<VirtualTryOn />} />
        <Route path="/paymentPage" element={<PaymentPage />} />
        <Route path="/CreateAnAccount" element={<SignUp />} />
        <Route path="/addArtists" element={<AddArtsits />} />
        <Route
          path="/componentOnNavbar"
          element={<KeratinLashLiftComponentOfNavbar />}
        />
        <Route
          path="/KeratinLashLiftPage"
          element={<KeratinLashLiftServicePage />}
        />
        <Route path="/Waxing" element={<WaxingPage />} />
        <Route path="/Removal" element={<RemovalPage />} />
        <Route
          path="/VolumeLashExtenionPage"
          element={<VolumeEyelashextensionPage />}
        />
        <Route
          path="/MegaVolumeLashExtenionPage"
          element={<MegaVolumeExtensionPage />}
        />
        <Route path="/HybridsExtenionPage" element={<HybridsExtensionPage />} />
        <Route
          path="/ClassicsExtenionPage"
          element={<ClassicsExtensionpage />}
        />
        <Route
        path="/ProductDesc"
        element={<ProductDesc /> }
      />
      <Route
      path="/AddServiceImages"
      element={<AddServiceImages /> }
    />
        <Route
          path="/scheduleAnAppointmentNowWithAnnosune"
          element={<ScheduleAnAppointmentNowWithAnnosune />}
        />
        <Route
        path="/ProductDesc/checkout"
        element={<Checkout />}
      />
      
      </Routes>
    </BrowserRouter>
  );
  //  <Navbar/>
}

export default App;
