import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from "../../images/KeratinlashLiftService.jpg";
import ClasiicLashFullLiftAnnosuneImg from "../../images/ClassicLashFullLiftImg.jpg";
import InLeiLashLiftAndFillersImg from "../../images/InLeiLashLiftAndFillersImg.jpg";
import ClasiicLashFullLiftHappyImg from '../../images/ClassicsByHappyImg.jpg'
import ClasiicLashFullLiftJackieImg from '../../images/JackieWeek2ClassiclashFill.jpg'
import ClasiicLashFullLiftShellyImg from '../../images/ClassicsBySheelyImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerClassics">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatinerClassics">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={ClasiicLashFullLiftAnnosuneImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfo">
              <div className="KeratinLashLiftForNavbarPriceClassics">$185.00</div>
              <div className="KeratinLashLiftForNavbarHeading">
                CLASSICS LASH FULL SET
              </div>
              <div className="KeratinLashLiftForNavbarContent">
                Welcome to EmbelLASHES, where we provide classic full sets of
                individual faux mink lashes for a glamorous and alluring look.
                Our skilled and experienced lash stylists will carefully apply
                the lashes to your natural lashes, resulting in a seamless and
                beautiful finish.You'll leave our salon feeling rested and
                fabulous! We promise that you'll love your new look!
                <br></br>
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowKeratinLashLiftForNavbarBtnClassics">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerClassics">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={ClasiicLashFullLiftAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfo">
              <div className="KeratinLashLiftForNavbarPriceClassics">$80.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
                2 WEEKS CLASSIC LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
                Welcome to EmbelLASHes! We are dedicated to providing our
                clients with the best possible experience. To ensure that all
                our clients receive the highest quality service, we require that
                all extensions be attached prior to their appointment. If less
                than 40% of extensions are attached, you will be charged the
                after 3 week price.<br></br>
                <br></br> Please arrive with squeaky clean lashes to avoid a
                *$35 makeup removal fee
                <br></br>


              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerClassics">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={ClasiicLashFullLiftAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfo">
              <div className="KeratinLashLiftForNavbarPriceClassics">$90.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
                3 WEEKS CLASSIC LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


              <br></br><br></br>
              Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
                   <br></br>             </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerClassics">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={ClasiicLashFullLiftAnnosuneImg}
          />
          <div className="KeratinLashLiftForNavbarInfo">
            <div className="KeratinLashLiftForNavbarPriceClassics">$150.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
              4 WEEKS CLASSIC LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            At EmbelLASH, we want to ensure that our clients are getting the
                most out of their lash extension experience. That's why we
                require that at least 30% of extensions be attached after 3
                weeks. If less than 30% are attached, you will be bumped up to
                the 4 week price.
                <br></br>
                <br></br>
                Please arrive with squeaky clean lashes to avoid a *$35 makeup
                removal fee
                <br></br>



</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerClassics">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={ClasiicLashFullLiftAnnosuneImg}
          />
          <div className="KeratinLashLiftForNavbarInfo">
            <div className="KeratinLashLiftForNavbarPriceClassics">$175.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            FOREIGN CLASSIC LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            If you are coming from another salon for a Classic Fill, please
                make sure that at least 30% of your existing lash extensions are
                still intact. Our technicians may not be able to work with your
                lashes if they are in poor condition and a removal fee will be
                charged.
                <br></br>
                <br></br>
                Please arrive with squeaky clean lashes to avoid a *$35 makeup
                removal fee
                <br></br>

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerClassics">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={ClasiicLashFullLiftHappyImg}
          />
          <div className="KeratinLashLiftForNavbarInfo">
            <div className="KeratinLashLiftForNavbarPriceClassics">$135.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            CLASSICS LASH FULL SET
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            Welcome to EmbelLASHES, where we provide classic full sets of individual faux mink lashes for a glamorous and alluring look.
     Our skilled and experienced lash stylists will carefully apply the lashes to your natural lashes, resulting in a seamless and beautiful finish.You'll leave our salon feeling rested and fabulous! We promise that you'll love your new look!
                <br></br>
              

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="KeratinLashLiftForNavbarConatinerClassics">
        <img
          className="KeratinLashLiftForNavbarImg"
          src={ClasiicLashFullLiftHappyImg}
        />
        <div className="KeratinLashLiftForNavbarInfo">
          <div className="KeratinLashLiftForNavbarPriceClassics">$65.00</div>
          <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
          2 WEEKS CLASSIC LASH FILL
          </div>
          <div className="KeratinLashLiftForNavbarContent">
          Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br>
            

</div>
          <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
            <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
              Get Your Slot Now!!
            </button>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="KeratinLashLiftForNavbarConatinerClassics">
        <img
          className="KeratinLashLiftForNavbarImg"
          src={ClasiicLashFullLiftHappyImg}
        />
        <div className="KeratinLashLiftForNavbarInfo">
          <div className="KeratinLashLiftForNavbarPriceClassics">$85.00</div>
          <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
          3 WEEKS CLASSIC LASH FILL
          </div>
          <div className="KeratinLashLiftForNavbarContent">
          At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br>
            

</div>
          <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
            <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
              Get Your Slot Now!!
            </button>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatinerClassics">
      <img
        className="KeratinLashLiftForNavbarImg"
        src={ClasiicLashFullLiftHappyImg}
      />
      <div className="KeratinLashLiftForNavbarInfo">
        <div className="KeratinLashLiftForNavbarPriceClassics">$145.00</div>
        <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
        FOREIGN CLASSIC LASH FILL
        </div>
        <div className="KeratinLashLiftForNavbarContent">
        If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


        <br></br><br></br>
        Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
            <br></br>
          

</div>
        <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
          <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
            Get Your Slot Now!!
          </button>
        </div>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
  <div className="KeratinLashLiftForNavbarConatinerClassics">
    <img
      className="KeratinLashLiftForNavbarImg"
      src={ClasiicLashFullLiftJackieImg}
    />
    <div className="KeratinLashLiftForNavbarInfo">
      <div className="KeratinLashLiftForNavbarPriceClassics">$65.00</div>
      <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
      2 WEEKS CLASSIC LASH FILL
      </div>
      <div className="KeratinLashLiftForNavbarContent">
      Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
      <br></br>
        

</div>
      <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
        <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
          Get Your Slot Now!!
        </button>
      </div>
    </div>
  </div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftJackieImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$80.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    3 WEEKS CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftJackieImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$95.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    4 WEEKS CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$135.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    CLASSICS LASH FULL SET
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    Welcome to EmbelLASHES, where we provide classic full sets of individual faux mink lashes for a glamorous and alluring look.
    Our skilled and experienced lash stylists will carefully apply the lashes to your natural lashes, resulting in a seamless and beautiful finish.You'll leave our salon feeling rested and fabulous! We promise that you'll love your new look!
    <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$65.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    2 WEEKS CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$85.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    3 WEEKS CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerClassics">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfo">
    <div className="KeratinLashLiftForNavbarPriceClassics">$145.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    FOREIGN CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtnClassics ">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
