import React from 'react'
import './BookNowButtonforMicrobladingService.css'
import { Link } from 'react-router-dom'
const Button = () => {
  return (
   <Link to='/bookNowForMicroBladingService'>
   <button className='btnBookNowForMicroBladingService'>Book Now!</button>
   </Link>
  );
}

export default Button