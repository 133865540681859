
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";
import BookNowButton from '../Buttons/BookNowButton'
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const LashLiftCourseForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerCoursesLashLift'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    
    <SwiperSlide ><div className="KeratinLashLiftForNavbarConatinerLashLiftCourse">
    <img className="KeratinLashLiftForNavbarImg" src={InLeiLashLiftAndFillersImg}/>
    <div className="KeratinLashLiftForNavbarInfoLashLiftCertificationCourse">
    <div className="KeratinLashLiftForNavbarHeadingLashLiftCourse ">Lash Lift/Tint Course</div>
    <div className="KeratinLashLiftForNavbarContent">Embellashes Lash Lift/Tint Course is designed for you to have an edge in the industry. Lash lift is a technique where clients' natural eyelashes are enhanced and curled. The simplest way to think of a lash lift is thinking of it as a perm for your lashes. A simple yet effective approach. This course will allow you, as a stylist, to provide a wider range of services on your service list. With this, we will also teach you Brow Lamination as an add on!
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnCourses">
    <BookNowButton/>
    </div>
    </div>
    </div>
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default LashLiftCourseForNavbar