import React from "react";
import ViewAllBlog from "./Buttons/ViewAllBlog";
import "./OurBlog.css";
import OurLeftBlogImg from "../images/ourblogImg.jpg";
import ReadMoreEyelashExtensionCostInFresno from "../components/Buttons/ReadMoreEyelashExtensionCostInFresno";
import ReadMoreSmileThearpyCostInFresno from "../components/Buttons/ReadMoreSmileThearpyCostInFresno";
import OurRightBlogImg from "../images/Teeth-whitening-course-fresno.jpg";
const OurBlog = () => {

  return (
    <div className="blogHeadingAndmainBlogContainer">
      <h1 className="blogHeading">FROM OUR BLOG</h1>
      <div className="mainBlogContainer">
        <div className="leftSection">
          <h2 className="BlogHeading2">
            Made using clean, non-toxic ingredients, our products are designed
            for everyone.
          </h2>
          <div className="viewAllBlogButton">
            <ViewAllBlog />
          </div>
        </div>
        <div className="forResponsiveToKeepBothLeftAndRightDown">
        <div className="CenterSection">
          <img src={OurLeftBlogImg} className="leftImage" />
          <h1 className="headingBelowImage">Eyelash Extensions Fresno</h1>
          <h2 className="heading2BelowImage">
            {" "}
            How Much Eyelash Extensions Cost In Fresno?
          </h2>
          <ReadMoreEyelashExtensionCostInFresno />
        </div>
        <div className="RightSection">
          <img src={OurRightBlogImg} className="RightImageOfBlog" />
          <h1 className="headingBelowRightImage">Smile Therapy</h1>
          <h2 className="heading2BelowRightImage">
            {" "}
            What Will Be The Smile Thearpy Course Cost In Fresno?
          </h2>
          <div className="ReadMoreSmileTherapyCostInFresno">
            <ReadMoreSmileThearpyCostInFresno />
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default OurBlog;
