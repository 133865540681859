import React from 'react'
import './ClassicLashCertificationCourse.css'
import ClassicLashCertificationcourseImg from '../images/ClassicLashCertificationCourseImg.png'
import BookNowButton from '../components/Buttons/BookNowButton'
const ClassicLashCertificationcourse = () => {
  return (
    <div className='classicLashCertificationCourseMainContainer'>
      <div className='classicLashCertificationCourseSection'>
      <img className='classicLashCertificationCourseimage' src={ClassicLashCertificationcourseImg}/>
        <div className='classicLashCertificationCourseHeading'>
         <h1 className='classicLashCertificationCourseSectionHeadingInfo'>
          Classic Lash Certification Course </h1>
    
            <div className='classicLashCertificationCourseSectionInfo'>Classic Certifications Program is designed for starting lash artists with no previous experience focusing on beginning your new career as smooth as possible with full understanding of the lash artistry. Classic Lashing technique which is adding a single lash extension to a single natural eyelash, giving it a longer, slightly fuller look. In this two-day classic lash extension course, you will learn a lot including product knowledge, full sets, fills, removals, client aftercare, eye design, and more.
            </div>
    <BookNowButton/>
        </div>
      </div>
    </div>
  )
}

export default ClassicLashCertificationcourse