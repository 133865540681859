

import Navbar from '../components/Navbar'
import './VolumeEyelashExtensionPage.css'

import ClasiicLashFullLiftAnnosuneImgVolumeByJackie from '../images/VolumeLashFilljackie.jpg'

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../images/upperLipWaxing.jpg'
import VolumeLashFullLiftAnnosuneImg from '../images/2WeekVolumeLashFillImg.jpg'
import Week3VolumeLashFillImg from '../images/3WeekVolumeLashFillImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Footer from '../components/Footer';
import MobileNavbar from '../components/Accordion/MobileNavbar';
const VolumeEyelashextensionPage = () => {
  return (
    <div>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
    <div className='VolumeExtensionPageConatiner-openThroughNavbar'>
    <div className='VolumeExtensionPageConatiner-openThroughNavbar-heading'>
    Volume Eyelash Extension
    </div>

    <div className='VolumeExtensionPageConatiner-openThroughNavbar-VolumeByAnnosuneHeading'>
    Volume By annosune
    </div>
    <div className='VolumeExtensionPageConatiner-openThroughNavbar-volumeByannosuneComponent'>
    <div className='HybridsByannosuneContainer-forVolumeEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForVolumeExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$250.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">VOLUME LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">If you're looking to add some serious volume and texture to your lashes, then look no further than volume lashes! This newer and more innovative technique involves hand-fanning out multiple fine, thin lashes and attaching them to one natural lash, giving your eyes a fuller, more dimensional look. With 2-10 lashes customized to your natural lash line, this is a great option for anyone wanting to make a bolder statement with their eye makeup.
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={VolumeLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$125.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={Week3VolumeLashFillImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$180.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 30% of your extensions are still attached. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
    Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    
  </Swiper>
    </div>
    <div className='backgroundColorLineFor-VolumeExtensionPageConatiner'></div>

    <div className='VolumeExtensionPageConatiner-openThroughNavbar-VolumeByAnnosuneHeading'>
    Volume By Jackie
    </div>

    <div className='VolumeExtensionPageConatiner-openThroughNavbar-volumeByannosuneComponent'>
    <div className='HybridsByannosuneContainer-forVolumeEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForVolumeExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImgVolumeByJackie}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$90.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImgVolumeByJackie}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$150.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 30% of your extensions are still attached. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
    Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
   
  </Swiper>
    </div>
    <div className='backgroundColorLineFor-VolumeExtensionPageConatiner'></div>

    </div>
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default VolumeEyelashextensionPage





  


