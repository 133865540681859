
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../../images/HybridsBySheelyImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Annosune Services/HybridsByAnnosune.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const HybridsByannosune = () => {
  return (
    <div className='HybridsByannosuneContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$155.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">HYBRID LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Do you want to add lash extensions but are unsure of which type is right for you? If so, then hybrid eyelash extensions is the one perfect for you! Hybrid lashes are a combination of classic and volume lash extensions, giving you the best of both worlds. They are ideal for those with naturally fine or slightly gappy lashes, as the volume fans can help fill in any sparse areas. So if you're looking for a versatile and flattering option, give hybrid lashes a try!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$75.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS HYBRID LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$120.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS HYBRID LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$165.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">HYBRID FOREIGN LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Please note that new clients with Hybrid lash extensions on from another stylist or saloon must have 30% of their existing lash extensions maintained.


<br></br><br></br>
No caffeine 1 hour prior to your appointment. No eye makeup or caffeine prior to your appointment.
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   
  </Swiper>
    </div>
  )
}

export default HybridsByannosune