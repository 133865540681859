import React from "react";
import Submit from "../components/Buttons/Submit";
import "./Footer.css";
import logoFooter from "../images/embellashesLogoFooter.png";
import { GrFacebook } from "react-icons/gr";
import { FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="containerFooter">
      <div className="embellashesInfo">
        <img src={logoFooter}  className='footerLogoImgg'/>
        <div className="embellashesInfoLines">
          We offer Lash & Brow services, including Single Semi-permanent Lash
          Extension and Microblading. We specialize in creating natural lash
          appearances and authentic 3D hair strokes!{" "}
        </div>
        <div className="socialMediaLinks">
          <ul>
            <li className="facebookicon">
              <GrFacebook />
            </li>
            <li className="instagramIcon">
            <a href='https://www.instagram.com/embellashesstudios/'> <FiInstagram /></a>
             
            </li>
          </ul>
        </div>
      </div>
      <div className="quickLinks">
        <h3 className="quickLinksheading">Quick Links</h3>
        <ul>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/">
              Home
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/termsAndConditions">
              Terms and Conditions
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/signIn">
              My account
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/readMoreBlog">
              Blog
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/career">
              Career
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/contactUs">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      <div className="quickLinks">
        <h3 className="quickLinksheading">Cart</h3>
        <ul>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/cartPage"></Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/bellalash">
              Bellalash Products
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/courses">
              Courses
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/products">
              Embellashes Products
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/findAStore">
              Find a store
            </Link>
          </li>
          <li className="quickLinksLines">
            <Link className="footerLinks" to="/smileTherapy">
              Smile Therapy
            </Link>
          </li>
        </ul>
      </div>
      <div className="subscribeWithUs">
        <h3 className="subscribeLine">Subscribe with Us</h3>
        <form>
          <label style={{ color: "black" }}>
            <Link className="email" to="/subscribed">
           <div className="emaiForFooter">Email :</div>
              
            </Link>

            <input
              type="text"
              name="email"
              placeholder="enter your email here"
              className="inputForEmailInFooter"
            />
          </label>
        </form>
        <Submit  />
      </div>
    </div>
  );
};

export default Footer;
