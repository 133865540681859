import React from 'react'
import './CheckoutProducts.css'
import { useStateValue } from './context/Context'
import PaymentPage from './PaymentPage'
const CheckoutProduct = ({image,title,price}) => {

  

  return (
    <div className='checkoutProduct'>
    <img className='checkoutProductImg' src={image}/>
    <div className='checkoutProductInfo'>
    <div className='checkoutProductTitle'>{title}</div>
    <div className='checkoutProductPrice'>${price}</div>
    </div>
    </div>
    
  )
}

export default CheckoutProduct