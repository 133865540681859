import React, { useState } from "react";
import { storage, db } from "../Firebase";
const AddArtsists = () => {
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNuber] = useState("");
  const [artistsDescription, setArtistDescription] = useState("");

  const [image, setImage] = useState(null);
  const [sucessMsg, setSuccessMsg] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [imageError, setImageError] = useState("");

  const types = ["image/jpg", "image/jpeg", "image/png", "image/PNG"];

  const handleArtistImg = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && types.includes(selectedFile.type)) {
        setImage(selectedFile);
        setImageError("");
      } else {
        setImage(null);
        setImageError("please select a valid file type");
      }
    } else {
      console.log("please select your file");
    }
  };

  const handleAddArtists = (e) => {
    e.preventDefault();
    // console.log(title,price);
    // console.log(image);
    const uploadTask = storage.ref(`artists-images/${image.name}`).put(image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(progress);
      },
      (error) => setUploadError(error.message),
      () => {
        storage
          .ref("artists-images")
          .child(image.name)
          .getDownloadURL()
          .then((url) => {
            db.collection("artists")
              .add({
                title,
                phoneNumber: Number(phoneNumber),
                artistsDescription,
                url,
              })
              .then(() => {
                setSuccessMsg("Artist added successfully");
                setTitle("");
                setPhoneNuber("");
                document.getElementById("file").value = "";
                setImageError("");
                setUploadError("");
                setTimeout(() => {
                  setSuccessMsg("");
                }, 3000);
              })
              .catch((e) => setUploadError(e.message));
          });
      }
    );
  };

  return (
    <div className="AddArtistsContainer">
      <form onSubmit={handleAddArtists}>
        <div className="AddArtistsHeading">Add Products</div>
        {sucessMsg && (
          <div>
            <div className="sucessMsg">{sucessMsg}</div>
          </div>
        )}
        <div className="AddArtistsTitle">
          {" "}
          Artists Title
          <input
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          ></input>
        </div>
        <div className="AddArtistsPhoneNumber">
          {" "}
          Artists Phone number
          <input
            type="string"
            onChange={(e) => setPhoneNuber(e.target.value)}
            value={phoneNumber}
          ></input>
        </div>
        <div className="AddArtistsDescription">
          {" "}
          Artists Description
          <input
            type="text"
            onChange={(e) => setArtistDescription(e.target.value)}
            value={artistsDescription}
          ></input>
        </div>
        <div className="AddArtistsImage">
          {" "}
          Upload Image
          <input type="file" id="file" onChange={handleArtistImg}></input>
          {imageError && (
            <div>
              <div className="errorImage-Msg">{imageError}</div>
            </div>
          )}
        </div>
        <div className="AddartistsButton">
          <button type="submit">Submit</button>
        </div>
        {uploadError && (
          <div>
            <div className="errorImg-Msg">{uploadError}</div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddArtsists;
