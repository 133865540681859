import KeratinlashLiftServiceImg from "../images/MicrobladePlusShadeImg.jpg";
import "./BrowsByKatelyn.css";
import waxingEyelasExtensionPage from "../images/Eyebrow-Touch-Up.jpg";
import classicServiceImg from "../images/FrecklesImg.jpg";
import hybridServiceImg from "../images/HennaEyebrowsiMg.jpg";
import volumeServiceImg from "../images/eyebrowLamination.jpg";
import megaVolumeServiceImg from "../images/angelRemovalImg.jpg";
import removalServiceImg from "../images/katelynMicrobladingForeignTouchupImg.jpg";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import BookNowButton from "../components/Buttons/BookNowButtonMicroBladingPage";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import {
  Autoplay,
  Pagination,
  Navigation,
  Mousewheel,
  FreeMode,
  EffectFade,
} from "swiper";
import MobileNavbar from "../components/Accordion/MobileNavbar";

const MicroBlading = () => {
  return (
    <div className="bodyOfBrowsBykatelynPage">
     <div className="BrowsByKatelynNavbar"><Navbar /></div> 
     <div className="browsByKatelynMobileNavbar"><MobileNavbar/></div>

      <div className="BrowsBykatelynServicePageHeading">Brows By Katelyn</div>
      <div className="microbladPlusShadePageFold1Container">
        <img
          className="microbladPlusShadeServicePageImg1"
          src={KeratinlashLiftServiceImg}
        />
        <div className="microbladPlusShadeServiceInfo">
          <div className="microbladPlusShadeThroughNavbarPrice"> $500.00</div>
          <div className="microbladPlusShadeServiceHeading">
            MICROBLADE + SHADE
          </div>

          <div className="microbladPlusShadeInfoBelowHeading">
            <div className="abovemicrobladPlusShadeServiceInfoBelowHeadingList">
              Redesign your eyebrows to create a more youthful or stylish look
            </div>

            <ul className="microbladPlusShadeServiceInfoBelowHeadingList">
              <li>Hair-like strokes mimic natural brows</li>
              <li>Lasts 1-2 years with one touch-up session</li>
              <li>No machines, no downtime</li>
            </ul>
            <div className="belowmicrobladPlusShadeServiceInfoBelowHeadingList">
              Microblading is ideal for individuals who desire to enhance the
              appearance of their eyebrows.
            </div>
          </div>

          <div className="bookNowButtonmicrobladPlusShadeService">
            <a
              href="https://www.vagaro.com/embellashes/book-now"
              target="_blank"
            >
              <button className="bookNowButtonOnThemicrobladPlusShadePage">
                Get Your Slot Now!
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="WaxingServicePageFold2Container">
        <div className="WaxingServiceInfo">
          <div className="microbladPlusShadeThroughNavbarPrice"> $250.00</div>
          <div className="WaxingServiceHeading-BrowsByKatelyn">
            Eyebrow Touch Up
          </div>

          <div className="WaxingServiceInfoBelowHeading">
            Must be confirmed with Katelyn. Fill for reoccurring Microblade +
            Shade clients
          </div>

          <div className="bookNowButtonWaxingService">
            <BookNowButton />
          </div>
        </div>
        <img
          className="WaxingServicePageImg2"
          src={waxingEyelasExtensionPage}
        />
      </div>

      <div className="EyelashExtensionServicePageFold3Container">
        <img
          className="EyelashExtensionServicePageImg1-BrowsByKatelyn"
          src={classicServiceImg}
        />
        <div className="KeratinlashLiftServiceInfo-BrowsByKatelyn ResponsiveKeratinlashLiftServiceInfo-BrowsByKatelyn">
          <div className="microbladPlusShadeThroughNavbarPrice-BrowsByKatelyn"> $100.00</div>

          <div className="KeratinlashLiftServiceHeading-BrowsByKatelyn responsiveHeadingForClassicLashExtensionService">
            Freckels
          </div>

          <div className="KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn responsiveKeratinlashLiftServiceInfoBelowHeading ">
            Freckle tattooing is a procedure in which a makeup artist inserts
            pigment in your cheeks and nose, to give you the perfect freckly
            look
          </div>

          <div className="bookNowButtonKeratinlashLiftService-BrowsByKateln responsivebookNowButtonKeratinlashLiftService">
            <BookNowButton />
          </div>
        </div>
      </div>

      <div className="EyelashExtensionServicePageFold2Container">
        <div className="WaxingServiceInfo">
          <div className="microbladPlusShadeThroughNavbarPrice"> $150.00</div>

          <div className="WaxingServiceHeading-BrowsByKatelyn responsiveHybridLashExtensionServiceHeading">
            Henna Eyebrows
          </div>

          <div className="WaxingServiceInfoBelowHeading">
            More definition by adding color to brows.
          </div>

          <div className=" toSetBookNowButtonInBrowsByKatelynPageInHennaEyebrows">
            <BookNowButton />
          </div>
        </div>
        <img className="WaxingServicePageImg2" src={hybridServiceImg} />
      </div>

      <div className="EyelashExtensionServicePageFold3Container responsiveVolumeLashExtensionServiceContainer">
        <img
          className="EyelashExtensionServicePageImg1-BrowsByKatelyn-Lamination"
          src={volumeServiceImg}
        />
        <div className="KeratinlashLiftServiceInfo-BrowsByKatelyn ResponsiveKeratinlashLiftServiceInfo-BrowsByKatelyn">
          <div className="microbladPlusShadeThroughNavbarPrice-BrowsByKatelyn"> $150.00</div>

          <div
            className="KeratinlashLiftServiceHeading-BrowsByKatelyn-Lamination 
    responsiveVolumeLashExtensionHeading"
          >
            Eyebrow Lamination
          </div>

          <div
            className="KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn-Lamination
    responsiveVolumeLashExtensionBelowHeading"
          >
            Enhancing brows for a fuller look
          </div>

          <div className="bookNowButtonKeratinlashLiftService tosetButtonOfEyebrowLamination">
            <BookNowButton />
          </div>
        </div>
      </div>

      <div className="EyelashExtensionServicePageFold2Container">
        <div className="WaxingServiceInfo">
          <div className="microbladPlusShadeThroughNavbarPrice"> $155.00</div>

          <div className="WaxingServiceHeading-BrowsByKatelyn">
            Angel Removal
          </div>

          <div className="WaxingServiceInfoBelowHeading">
            Redesign your eyebrows to create a more youthful or stylish look
            <ul className="WaxingServiceInfoBelowHeadingList">
              <li>
                (One session) Removal solution for previous eyebrows and lip
                liner permanent makeup. PLEASE NOTE: Treatment for eyebrows
                only- not for eyeliner, or body tattoos.{" "}
              </li>
              <li>
                {" "}
                Wear brow makeup again after just 24 hours! Number of sessions
                vary for each client to achieve optimal results.
              </li>
              <li>
                A 50% deposit is required to schedule your appointment.
                Reschedules are required within 48 hours of your appointment.
              </li>
            </ul>
          </div>

          <div className="bookNowButtonWaxingService">
            <BookNowButton />
          </div>
        </div>
        <img className="WaxingServicePageImg2" src={megaVolumeServiceImg} />
      </div>

      <div className="EyelashExtensionServicePageFold3Container">
        <img
          className="EyelashExtensionServicePageImg1-BrowsByKatelyn"
          src={removalServiceImg}
        />
        <div className="KeratinlashLiftServiceInfo-BrowsByKatelyn ResponsiveKeratinlashLiftServiceInfo-BrowsByKatelyn">
          <div className="microbladPlusShadeThroughNavbarPrice-BrowsByKatelyn"> $350.00</div>

          <div className="KeratinlashLiftServiceHeading-BrowsByKatelyn1">
            Microblading Foreign Touch up
          </div>

          <div className="KeratinlashLiftServiceInfoBelowHeading-BrowsByKatelyn">
            Microblading Touch up. Client has previous work done by another Brow
            Artist. Must be 12 weeks healed from previous appointment.
          </div>

          <div className="bookNowButtonKeratinlashLiftService-BrowsByKateln">
            <BookNowButton />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MicroBlading;
