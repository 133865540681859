 const services=[
    {
        id:1,
        serviceName:"Keratin Eyelash Lift  ",
        servicePrice:85,
        serviceImg:" https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FkeratineyelashLiftServiceImg.jpg?alt=media&token=5ad7112a-4b74-4dc0-a37a-fef106cbab45",

},
{
    id:2,
    serviceName:"InLei Lashlift + Fillers  ",
    servicePrice:110,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FInLeiLashLiftAndFillersImg.jpg?alt=media&token=621b9fee-474d-45e7-a4a6-766a1db5b3f4",
    
},
{
    id:3,
    serviceName:"Upper Lip Waxing ",
    servicePrice:15,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FupperLipWaxing.jpg?alt=media&token=78e59fbb-eecd-4143-9ffe-e77b1555b479",
},
{
    id:4,
    serviceName:"SideBurns Waxing  ",
    servicePrice:80,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FupperLipWaxing.jpg?alt=media&token=78e59fbb-eecd-4143-9ffe-e77b1555b479",
},
{
    id:5,
    serviceName:"Full Face",
    servicePrice:60,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FupperLipWaxing.jpg?alt=media&token=78e59fbb-eecd-4143-9ffe-e77b1555b479",
},
{
    id:6,
    serviceName:"Brows",
    servicePrice:25,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FupperLipWaxing.jpg?alt=media&token=78e59fbb-eecd-4143-9ffe-e77b1555b479",
},
{
    id:7,
    serviceName:"CLASSICS LASH FULL SET",
    servicePrice:185,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fclassssssics.jpg?alt=media&token=55acdb31-4b64-4e3d-91c5-fa6c8f6806b9",
},
{
    id:8,
    serviceName:"Hybrid LASH FULL SET",
    servicePrice:225,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fhybridsss.jpg?alt=media&token=3c3bc574-5266-4dc2-9b40-04d9b7422487"
    
},
{
    id:9,
    serviceName:"Volume LASH FULL SET",
    servicePrice:250,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fvolumeeeee.jpg?alt=media&token=3ff56fed-41fe-4b1d-a55d-c5dfe01e76a4"
    
},
{
    id:10,
    serviceName:"M V LASH FULL SET",
    servicePrice:375,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fmegavolumeee.png?alt=media&token=c39ca0d1-c964-44aa-91af-b95afe8a86a2"
    
},
{
    id:11,
    serviceName:"Eye Make-up Removal",
    servicePrice:35,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fremovalll.jpg?alt=media&token=05a0052c-6d4e-45bc-b374-af2c2760ba74"
    
},
{
    id:12,
    serviceName:"Eyelash extension Removal",
    servicePrice:40,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FremovalService.jpg?alt=media&token=ec9ab2c6-25cd-4180-aaf5-c6880956fbc3"
    
},
{
    id:13,
    serviceName:"MICROBLADE + SHADE",
    servicePrice:500,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Fmicroblading-3.jpg?alt=media&token=af2b009b-4e6f-4b95-b183-7272a6de7d4f"
    
},
{
    id:14,
    serviceName:"Eyebrow Touch Up",
    servicePrice:250,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2Feyebrowtouchup.jpg?alt=media&token=dd19bd83-d3e0-41a9-8b6b-7de00486f28f"
    
},
{
    id:15,
    serviceName:"Freckels",
    servicePrice:100,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FFrecklesImg.jpg?alt=media&token=9a64873b-ddb1-4b8d-af4f-8c9c54320881"
    
},
{
    id:16,
    serviceName:"Henna Eyebrows",
    servicePrice:150,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FHennaEyebrowsiMg.jpg?alt=media&token=d870909d-81df-470c-9668-b225c5bdef82"
    
},
{
    id:17,
    serviceName:"Eyebrow Lamination",
    servicePrice:150,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FeyebrowLamination.jpg?alt=media&token=9f6943f9-f026-48ff-b9f7-31c6b804885a"
    
},
{
    id:18,
    serviceName:"Angel Removal",
    servicePrice:155,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FangelRemovalImg.jpg?alt=media&token=9596fc4c-3c90-4ceb-ac12-b869e36f6603"
    
},
{
    id:19,
    serviceName:"Microblading Foreign Touch up",
    servicePrice:350,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FMicrobladingSliderImg6.PNG?alt=media&token=cd1de256-a480-4ce0-a69d-c429c729177f"
    
},
{
    id:20,
    serviceName:"Foreign Partial Fill",
    servicePrice:250,
    serviceImg:"https://firebasestorage.googleapis.com/v0/b/embellashes-9698a.appspot.com/o/product-images%2FforeignPartialFillImg.jpg?alt=media&token=72e474fd-4023-489f-b0cd-7e97608e0393"
    
},




]

export default services