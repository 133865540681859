import React from 'react'
import ClassicLashCertificationcourse from '../components/ClassicLashCertificationcourse'
import VolumeLashExtensionCourse from '../components/VolumeLashExtensionCourse'
import './Courses.css'
import LashLift_TintCourse from '../components/LashLift_TintCourse'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import MobileNavbar from '../components/Accordion/MobileNavbar'
const Courses = () => {
  return (
    <>
    <div className='coursesMainContainer'>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
    </div>
    <div className="CoursesPageHeading">OUR COURSES</div>
    <ClassicLashCertificationcourse/>
  <VolumeLashExtensionCourse/>
  <LashLift_TintCourse/>
  <Footer/>
  </>
  )
}

export default Courses