import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from "../../images/KeratinlashLiftService.jpg";
import ClasiicLashFullLiftAnnosuneImg from "../../images/ClassicLashFullLiftImg.jpg";
import InLeiLashLiftAndFillersImg from "../../images/InLeiLashLiftAndFillersImg.jpg";
import ClasiicLashFullLiftHappyImg from '../../images/ClassicsByHappyImg.jpg'
import ClasiicLashFullLiftJackieImg from '../../images/JackieWeek2ClassiclashFill.jpg'
import ClasiicLashFullLiftShellyImg from '../../images/ClassicsBySheelyImg.jpg'
import HybridsLashFullLiftAnnosuneImg from '../../images/HybridsByAnnosuneImg.jpg'
import HybridsLashFullLiftHappyImg from '../../images/HybridsBYHappyimg.jpg'
import  HybridsLashFullLiftJackieImg from '../../images/hybridLashFillJackie.jpg'
import HybridsLashFullLiftShellyImg from '../../images/HybridsBySheelyImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerHybrids">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatinerHybrids">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={HybridsLashFullLiftAnnosuneImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoHybrids">
              <div className="KeratinLashLiftForNavbarPrice">$225.00</div>
              <div className="KeratinLashLiftForNavbarHeading">
              HYBRID LASH FULL SET
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Do you want to add lash extensions but are unsure of which type is right for you? If so, then hybrid eyelash extensions is the one perfect for you! Hybrid lashes are a combination of classic and volume lash extensions, giving you the best of both worlds. They are ideal for those with naturally fine or slightly gappy lashes, as the volume fans can help fill in any sparse areas. So if you're looking for a versatile and flattering option, give hybrid lashes a try!
              <br></br>
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowKeratinLashLiftForNavbarBtn">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerHybrids">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={HybridsLashFullLiftAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfoHybrids">
              <div className="KeratinLashLiftForNavbarPrice">$95.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
              2 WEEKS HYBRID LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
              <br></br>

              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerHybrids">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={HybridsLashFullLiftAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfoHybrids">
              <div className="KeratinLashLiftForNavbarPrice">$150.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
              3 WEEKS HYBRID LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
              <br></br>           </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerHybrids">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={HybridsLashFullLiftAnnosuneImg}
          />
          <div className="KeratinLashLiftForNavbarInfoHybrids">
            <div className="KeratinLashLiftForNavbarPrice">$200.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            HYBRID FOREIGN LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            Please note that new clients with Hybrid lash extensions on from another stylist or saloon must have 30% of their existing lash extensions maintained.


            <br></br><br></br>
            No caffeine 1 hour prior to your appointment. No eye makeup or caffeine prior to your appointment.
                 <br></br>



</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerHybrids">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={HybridsLashFullLiftHappyImg}
          />
          <div className="KeratinLashLiftForNavbarInfoHybrids">
            <div className="KeratinLashLiftForNavbarPrice">$155.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            HYBRID LASH FULL SET
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            Do you want to add lash extensions but are unsure of which type is right for you? If so, then hybrid eyelash extensions is the one perfect for you! Hybrid lashes are a combination of classic and volume lash extensions, giving you the best of both worlds. They are ideal for those with naturally fine or slightly gappy lashes, as the volume fans can help fill in any sparse areas. So if you're looking for a versatile and flattering option, give hybrid lashes a try!
     <br></br>

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerHybrids">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={HybridsLashFullLiftHappyImg}
          />
          <div className="KeratinLashLiftForNavbarInfoHybrids">
            <div className="KeratinLashLiftForNavbarPrice">$175.00</div>
            <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
            2 WEEKS HYBRID LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
     <br></br>
              

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
      <div className="KeratinLashLiftForNavbarConatinerHybrids">
        <img
          className="KeratinLashLiftForNavbarImg"
          src={HybridsLashFullLiftHappyImg}
        />
        <div className="KeratinLashLiftForNavbarInfoHybrids">
          <div className="KeratinLashLiftForNavbarPrice">$120.00</div>
          <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
          3 WEEKS HYBRID LASH FILL
          </div>
          <div className="KeratinLashLiftForNavbarContent">
          With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
          <br></br>
            

</div>
          <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
            <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
              Get Your Slot Now!!
            </button>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="KeratinLashLiftForNavbarConatinerHybrids">
        <img
          className="KeratinLashLiftForNavbarImg"
          src={HybridsLashFullLiftHappyImg}
        />
        <div className="KeratinLashLiftForNavbarInfoHybrids">
          <div className="KeratinLashLiftForNavbarPrice">$165.00</div>
          <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
          HYBRID FOREIGN LASH FILL
          </div>
          <div className="KeratinLashLiftForNavbarContent">
          Please note that new clients with Hybrid lash extensions on from another stylist or saloon must have 30% of their existing lash extensions maintained.


          <br></br><br></br>
          No caffeine 1 hour prior to your appointment. No eye makeup or caffeine prior to your appointment.
               <br></br>

</div>
          <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
            <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
              Get Your Slot Now!!
            </button>
          </div>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatinerHybrids">
      <img
        className="KeratinLashLiftForNavbarImg"
        src={HybridsLashFullLiftJackieImg}
      />
      <div className="KeratinLashLiftForNavbarInfoHybrids">
        <div className="KeratinLashLiftForNavbarPrice">$85.00</div>
        <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
        2 WEEK HYBRID LASH FILL
        </div>
        <div className="KeratinLashLiftForNavbarContent">
        With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
        <br></br>
          

</div>
        <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
          <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
            Get Your Slot Now!!
          </button>
        </div>
      </div>
    </div>
  </SwiperSlide>
  <SwiperSlide>
  <div className="KeratinLashLiftForNavbarConatinerHybrids">
    <img
      className="KeratinLashLiftForNavbarImg"
      src={HybridsLashFullLiftJackieImg}
    />
    <div className="KeratinLashLiftForNavbarInfoHybrids">
      <div className="KeratinLashLiftForNavbarPrice">$130.00</div>
      <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
      3 WEEK HYBRID LASH FILL
      </div>
      <div className="KeratinLashLiftForNavbarContent">
      With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
      <br></br>
        

</div>
      <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
        <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
          Get Your Slot Now!!
        </button>
      </div>
    </div>
  </div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={HybridsLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$155.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    HYBRID LASH FULL SET
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    Do you want to add lash extensions but are unsure of which type is right for you? If so, then hybrid eyelash extensions is the one perfect for you! Hybrid lashes are a combination of classic and volume lash extensions, giving you the best of both worlds. They are ideal for those with naturally fine or slightly gappy lashes, as the volume fans can help fill in any sparse areas. So if you're looking for a versatile and flattering option, give hybrid lashes a try!
    <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={HybridsLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$75.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    2 WEEKS HYBRID LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
     <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={HybridsLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$120.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    3 WEEKS HYBRID LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    With the Hybrid technique, you get a set of lashes created by combining two distinct lash application techniques..<br></br><br></br>  You will be charged the next service price if 40% of your lash extensions are not left
    
     <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={HybridsLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$165.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    HYBRID FOREIGN LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    Please note that new clients with Hybrid lash extensions on from another stylist or saloon must have 30% of their existing lash extensions maintained.


<br></br><br></br>
No caffeine 1 hour prior to your appointment. No eye makeup or caffeine prior to your appointment.
     <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$85.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    3 WEEKS CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>
<div className="KeratinLashLiftForNavbarConatinerHybrids">
  <img
    className="KeratinLashLiftForNavbarImg"
    src={ClasiicLashFullLiftShellyImg}
  />
  <div className="KeratinLashLiftForNavbarInfoHybrids">
    <div className="KeratinLashLiftForNavbarPrice">$145.00</div>
    <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
    FOREIGN CLASSIC LASH FILL
    </div>
    <div className="KeratinLashLiftForNavbarContent">
    If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br>
      

</div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
      <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftHybrids">
        Get Your Slot Now!!
      </button>
    </div>
  </div>
</div>
</SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
