import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from '../../images/KeratinlashLiftService.jpg'
import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainer">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatiner">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={KeratinLashLiftServiceImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoRemoval">
              <div className="RemovalForNavbarPrice">$35.00</div>
              <div className="RemovalForNavbarHeading">
              Eye Make-up Removal Service  
              </div>
              <div className="UpperLipWaxingForNavbarContent">
              Please come super clean. Our Lash Artist will only work with clean Lashes! At EmbelLASH Studio we will get give consistency and quality service.<br></br> As a Bella Lash Sponsored Lash Artist, EmbelLASHes works with on top tier health benefitting companies and proudly represents and recommends Bella Lash Care and After Care beauty products.
                
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowRemovalForNavbarBtn">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatiner">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={InLeiLashLiftAndFillersImg}
            />
            <div className="KeratinLashLiftForNavbarInfoRemoval">
              <div className="KeratinLashLiftForNavbarPrice">$40.00</div>
              <div className="EyelashExtensionRemovalForNavbarHeading">
              Eyelash extension Removal
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Removal of extensions safely without harming natural Lash


              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforSideburnWaxingInNavbar">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        
      </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
