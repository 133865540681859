import Navbar from '../components/Navbar'
import './MegaVolumeExtensionPage.css'

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../images/upperLipWaxing.jpg'
import VolumeLashFullLiftAnnosuneImg from '../images/2WeekVolumeLashFillImg.jpg'
import Week3VolumeLashFillImg from '../images/3WeekVolumeLashFillImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './ClassicssExtensionPage.css'

// import required modules
import { Autoplay, Pagination, Navigation,Mousewheel,
  FreeMode,
  EffectFade, } from "swiper";
import Footer from '../components/Footer';
import MobileNavbar from '../components/Accordion/MobileNavbar';

const ClassicsExtensionpage = () => {
  return (
    <div>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
    <div className='ClassicsExtensionPageConatiner-openThroughNavbar'>
    <div className='ClassicsExtensionPageConatiner-openThroughNavbar-heading'>
     Classic Eyelash Extension
    </div>

    <div className='ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading'>
    Classics By Annosune
    </div>
    <div className='ClassicsByannosuneContainer-forClassicsEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    loop={true}
          effect={"fade"}
          mousewheel={false}
          freeMode={false}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation,Mousewheel,
      FreeMode,
      EffectFade,]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForClassicsExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$185.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">CLASSICS LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHES, where we provide classic full sets of individual faux mink lashes for a glamorous and alluring look.
     Our skilled and experienced lash stylists will carefully apply the lashes to your natural lashes, resulting in a seamless and beautiful finish.You'll leave our salon feeling rested and fabulous! We promise that you'll love your new look!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg80" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo80">
    <div className="ClasiicLashFullLiftAnnosunePrice">$80.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$90.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$150.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">4 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$175.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">FOREIGN CLASSIC LASH FILL</div>
   <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage"> If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
  </Swiper>
    </div>
    <div className='backgroundColorLineFor-VolumeExtensionPageConatiner'></div>
    <div className='ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading'>
    Classics By Jackie
    </div>
    <div className='ClassicsByannosuneContainer-forClassicsEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    loop={true}
          effect={"fade"}
          mousewheel={false}
          freeMode={false}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation,Mousewheel,
      FreeMode,
      EffectFade,]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForClassicsExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg80" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo80">
    <div className="ClasiicLashFullLiftAnnosunePrice">$65.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$80.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br><br></br>
        **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$95.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">4 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br><br></br>
        **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    </Swiper>
    </div>
    <div className='backgroundColorLineFor-VolumeExtensionPageConatiner'></div>
    <div className='ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading'>
    Classics By Happy
    </div>
    <div className='ClassicsByannosuneContainer-forClassicsEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    loop={true}
          effect={"fade"}
          mousewheel={false}
          freeMode={false}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForClassicsExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$135.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">CLASSICS LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHES, where we provide classic full sets of individual faux mink lashes for a glamorous and alluring look.
     Our skilled and experienced lash stylists will carefully apply the lashes to your natural lashes, resulting in a seamless and beautiful finish.You'll leave our salon feeling rested and fabulous! We promise that you'll love your new look!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$65.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$85.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$145.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">FOREIGN CLASSIC LASH FILL</div>
   <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage"> If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
  </Swiper>
    </div>
    <div className='backgroundColorLineFor-VolumeExtensionPageConatiner'></div>
    <div className='ClassicsExtensionPageConatiner-openThroughNavbar-ClassicsByAnnosuneHeading'>
    Classics By Shelly
    </div>
    <div className='ClassicsByannosuneContainer-forClassicsEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    loop={true}
          effect={"fade"}
          mousewheel={false}
          freeMode={false}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation,Mousewheel,
      FreeMode,
      EffectFade,]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForClassicsExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$135.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">CLASSICS LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHES, where we provide classic full sets of individual faux mink lashes for a glamorous and alluring look.
     Our skilled and experienced lash stylists will carefully apply the lashes to your natural lashes, resulting in a seamless and beautiful finish.You'll leave our salon feeling rested and fabulous! We promise that you'll love your new look!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$65.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$85.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   <SwiperSlide>
   <div className="ClasiicLashFullLiftAnnosuneConatiner">
   <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
   <div className="ClasiicLashFullLiftAnnosuneInfo">
   <div className="ClasiicLashFullLiftAnnosunePrice">$145.00</div>
   <div className="ClasiicLashFullLiftAnnosuneHeading">FOREIGN CLASSIC LASH FILL</div>
   <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage"> If you are coming from another salon for a Classic Fill, please make sure that at least 30% of your existing lash extensions are still intact. Our technicians may not be able to work with your lashes if they are in poor condition and a removal fee will be charged. 


<br></br><br></br>
Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
   </div>
   <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
   <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
   </div>
   </div></SwiperSlide>
  </Swiper>
    </div>
    </div>
    <Footer/>
    </div>
   
  )
}

export default ClassicsExtensionpage