
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../../images/VolumeLashFilljackie.jpg'

import Week3VolumeLashFillImg from '../../images/3WeekVolumeLashFillImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Annosune Services/HybridsByAnnosune.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const HybridsByannosune = () => {
  return (
    <div className='HybridsByannosuneContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$90.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$150.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS VOLUME LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 30% of your extensions are still attached. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
    Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
   
  </Swiper>
    </div>
  )
}

export default HybridsByannosune