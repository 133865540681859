import React from 'react'
import eyelashExtensionImg from '../images/eyelashExtensionImg.jpg'
import './EyelashServices2.css'
import BookNowButton from '../components/Buttons/BookNowButton'

const EyeLashServices2 = () => {
  return (
    <div className='mainFlexEyeLashServiceOfHomePageContainer'>
    <div className='mainflexEyeLashServiceOfHomePageContainerHeading'>
    <p className='mainflexEyeLashServiceOfHomePageContainerHeading1'>Eyelash Services </p>
  
    <p className='mainflexEyeLashServiceOfHomePageContainerHeading2' >Embellashes provides a variety of Eyelash Services. We can  </p>
   <p className='mainflexEyeLashServiceOfHomePageContainerHeading3'>enhance your natural lashes with LASH</p>
    
    <p className='mainflexEyeLashContainerServiceOfHomePageHeading2ndPart'> LIFTs or we can add length and make </p>
   <p className='mainflexEyeLashContainerServiceOfHomePageHeading3rdPart'> your lashes fuller with EYELASH </p>
   <p className='mainflexEyeLashContainerServiceOfHomePageHeading4thPart'>EXTENSIONS.</p>
   
  <p className='mainflexEyeLashContainerServiceOfHomePageHeading5'>Book your slots now!</p>
  <BookNowButton/>
  </div>
  <img className='rightImgEyeLashServiceOfHomePageFlexContainer' src={eyelashExtensionImg}/>
    
    </div>
  )
}

export default EyeLashServices2