import React,{useState,useEffect} from 'react'
import { db } from '../Firebase';
import AllProductsCard from './AllProductsCard'
const AllProductsPage = () => {
  const [products, setProducts] = useState([]);

    const getProducts = async()=>{
        const products =await db.collection('Products').get();
        const productsArray =[];
        for(var snap of products.docs){
          var data = snap.data();
          data.ID =snap.id;
          productsArray.push({
            ...data
          })
          if(productsArray.length=== products.docs.length){
            setProducts(productsArray);
          }
        }
        // console.log(productsArray,"hi lolu");
      }

      useEffect(() => {
        getProducts();
       
       }, [])
  return (

    <div>
     {products.length > 0 &&(
    <div>
    <AllProductsCard products={products} />
   </div>
  )}
  {products.length<1 && (
    <div> please wait...</div>
  )}
    </div>
  )
}

export default AllProductsPage