import React from 'react'
import CheckoutProduct from './CheckoutProduct';
import { useStateValue } from './context/Context';
import StripeCheckout from 'react-stripe-checkout'
import { getBasketTotal } from './context/Reducer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
toast.configure();
const PaymentPage = () => {

    const [{basket,user}, dispatch]=useStateValue();
    const navigate= useNavigate();
    const handleToken=async(token)=>{
        // console.log(token);
        const cart ={name:'All Products', TotalPrice:getBasketTotal(basket)}
        const response = await axios.post('http://localhost:8080/paymentPage',{
            token,
            cart
        })
        // console.log(response);
        let {status} =response.data;
        // console.log(status);
        if(status=== 'success'){
            navigate('/');
            toast.success('your order has been placed successfully',{
                position:'top-right',
                autoclose:5000,
                hideProgressBar:false,
                closeOnClick:true,
                pauseOnHover:false,
                draggable:false,
                progress:undefined,
            });
        }else{
            alert('something went wrong in paymentPage ')
        }
    }
  return (
    <div className='payment'>
    <div className='paymentContainer'>
{/*Payment section-review items */}
<div className='paymentUser'> Hello{user.email}</div>
<div className='paymentItemsTitle'>Review Items:</div>
<div className='paymentItems'>
{basket.map(item=>(
    <CheckoutProduct
    title={item.title}
                  image={item.image}
                  price={item.price}/>
))}
</div>

{/*Payment section-payment method */}
<div className='paymentMethodTitle'>Payment Method</div>
<div className='paymentMethod'>
{/*stripe magic*/}
<StripeCheckout
stripeKey='pk_test_51LB1rdKzcBEwpZxcpbPeTr1Zi5XBCqXQllsxypGKCxX7qikLJLeghu6At3wtONR0REmMxWYjK9MJAnlBzNrIRbN300ieGovArj'
token={handleToken}
billingAddress
shippingAddress
name='All Products'
amount={getBasketTotal(basket)*100}
></StripeCheckout>

</div>


    </div>
    </div>
  )
}

export default PaymentPage