import React from "react";
import { useLocation } from "react-router-dom";
import MobileNavbar from "./Accordion/MobileNavbar";
import "./Checkout.css";
import CheckoutProduct from "./CheckoutProduct";
import { useStateValue } from "./context/Context";
import Navbar from "./Navbar";
import NewProducts from "./NewProducts";
import Subtotal from "./Subtotal";
const Checkout = () => {
  const [{ basket, user }, dispatch] = useStateValue();
  // console.log(user.email);
  const basketT = basket
  // console.log(basketT,"this is love");
  const basket1 = JSON.parse(localStorage.getItem("basketItem"));
  // console.log(basket1,"jjjjjjjjjjj");
  const location=useLocation();
  // console.log(location,"hjfuvkkoi");

  if(location.length>0){
    return(
      <div>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
      <div className="checkout">
        <div className="checkoutMainHeading"> Your Basket</div>
        <div className="checkoutLeftAndright">
          <div className="checkoutLeft">
            <div className="checkoutTitle">
              <div className="checkoutHeadingConatiner">
  <h3>Hello , </h3>
                <h2 className="checkoutHeading">Your Shopping Basket </h2>

                {/*here goes the basketItem*/}
              </div>
              {basket1.map((item) => (
                <CheckoutProduct
                  // NewProducts={NewProducts}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                />
              ))}

              <div className='checkoutProduct'>
    <img className='checkoutProductImg' src={location.state.image}/>
    <div className='checkoutProductInfo'>
    <div className='checkoutProductTitle'>{location.state.title}</div>
    <div className='checkoutProductPrice'>${location.state.price}</div>
    </div>
    </div>

            </div>
          
          </div>
          <div className="checkoutRight">
            {/*subtotal component*/}
            <h2 className="yourSubtotal">Your Subtotal</h2>
            <Subtotal />
          </div>
        </div>
      </div>
      </div>
    )
  
  }else{
  return (
    <>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
      <div className="checkout">
        <div className="checkoutMainHeading"> Your Basket</div>
        <div className="checkoutLeftAndright">
          <div className="checkoutLeft">
            <div className="checkoutTitle">
              <div className="checkoutHeadingConatiner">
  <h3>Hello , </h3>
                <h2 className="checkoutHeading">Your Shopping Basket </h2>

                {/*here goes the basketItem*/}
              </div>
              {basket1.map((item) => (
                <CheckoutProduct
                  // NewProducts={NewProducts}
                  title={item.title}
                  image={item.image}
                  price={item.price}
                />
              ))}

            

            </div>
          
          </div>
          <div className="checkoutRight">
            {/*subtotal component*/}
            <h2 className="yourSubtotal">Your Subtotal</h2>
            <Subtotal />
          </div>
        </div>
      </div>

    </>
              
  );
              }
};

export default Checkout;
