import fanAddictsByAnnosune from "../images/fanAddictsByAnnosune.png";
import handheldMirrorImg from "../images/handheldMirror.png";
import OctagonAdhesiveCrystalImg from "../images/octagonAdhesiveCrystal.png";
import japeneseSkinTapeImg from "../images/JapeneseSkinTape.png";
import "./EmbellashesProductsCard.css";

import Imagee from "../images/disposableApplicator.png";

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import nanoMisterImg from "../images/nanoMisterImg.jpg";
import GlassLashTileImg from "../images/glassLashTileImg.png";
import MicroBrushSwabsImg from "../images/MicroBrushSwabsImg.png";
import BladeLessFanImg from "../images/bladelessFan.png";
import NewProducts from "../components/NewProducts";

const EmbellashesProductCard = ({ products,addToCart }) => {
  // console.log(products);
 
  

  return (
    <>
      <div className="allThingsContainer ">
        <h1 className="headingOurProducts"> Our Products</h1>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          // pagination={{
          //   clickable: true,
          // }}
          autoplay={{
            delay: 100000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {products?.map((product) => {
   
            return (
              
           
                <SwiperSlide>
             
                <NewProducts key={product.ID} NewProducts={product} addToCart={addToCart}  />
           </SwiperSlide>

            
             )
            }
          )}
        </Swiper>
      </div>
    </>
  );
};

export default EmbellashesProductCard;
