import React from 'react'
import './Services/KeratinLashLiftService.css'
import BuyNow from './Buttons/BuyNow'
const AllServices = ({serviceName,serviceImg,servicePrice}) => {
    
  return (
    <div className='KeratinLashLiftServiceContainer'>
    <div className='KeratinLashLiftServiceCard'>
    <div className='imgFolderKeratinLashLiftService'>
    <img className='KeratinLashLiftServiceImg' src={ serviceImg} />
    </div>
    <h1 className='productKeratinLashLiftServiceHeading'>
   {serviceName}</h1>
   
    <h2 className='productKeratinLashLiftServicePrice'>
${servicePrice}
    </h2>
    <BuyNow/>
    </div>
</div>
  )
}

export default AllServices