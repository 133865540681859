import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import VolumeLashFullLiftAnnosuneImg1 from '../../images/2WeekVolumeLashFillImg.jpg'
import Week3VolumeLashFillImg from '../../images/3WeekVolumeLashFillImg.jpg'
import VolumeLashFullLiftAnnosuneImg2 from '../../images/VolumeLashFilljackie.jpg'

import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerVolume">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatinerVolume">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={VolumeLashFullLiftAnnosuneImg1}
            />
            
            <div className="KeratinLashLiftForNavbarInfoVolume">
              <div className="KeratinLashLiftForNavbarPrice">$250.00</div>
              <div className="KeratinLashLiftForNavbarHeadingVolume">
              VOLUME LASH FULL SET
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              If you're looking to add some serious volume and texture to your lashes, then look no further than volume lashes! This newer and more innovative technique involves hand-fanning out multiple fine, thin lashes and attaching them to one natural lash, giving your eyes a fuller, more dimensional look. With 2-10 lashes customized to your natural lash line, this is a great option for anyone wanting to make a bolder statement with their eye makeup.
              <br></br>
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowKeratinLashLiftForNavbarBtn">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerVolume">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={VolumeLashFullLiftAnnosuneImg1}
            />
            <div className="KeratinLashLiftForNavbarInfoVolume">
              <div className="KeratinLashLiftForNavbarPrice">$125.00</div>
              <div className="KeratinLashLiftForNavbarHeadingVolume ">
              2 WEEKS VOLUME LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
     <br></br>

              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftVolume">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerVolume">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={Week3VolumeLashFillImg}
            />
            <div className="KeratinLashLiftForNavbarInfoVolume">
              <div className="KeratinLashLiftForNavbarPrice">$180.00</div>
              <div className="KeratinLashLiftForNavbarHeadingVolume ">
              3 WEEKS VOLUME LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 30% of your extensions are still attached. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
              Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
               <br></br>          </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
                <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftVolume">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerVolume">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={VolumeLashFullLiftAnnosuneImg2}
          />
          <div className="KeratinLashLiftForNavbarInfoVolume">
            <div className="KeratinLashLiftForNavbarPrice">$90.00</div>
            <div className="KeratinLashLiftForNavbarHeadingVolume ">
            2 WEEKS VOLUME LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
            <br></br>

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftVolume">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="KeratinLashLiftForNavbarConatinerVolume">
          <img
            className="KeratinLashLiftForNavbarImg"
            src={VolumeLashFullLiftAnnosuneImg2}
          />
          <div className="KeratinLashLiftForNavbarInfoVolume">
            <div className="KeratinLashLiftForNavbarPrice">$150.00</div>
            <div className="KeratinLashLiftForNavbarHeadingVolume ">
            3 WEEKS VOLUME LASH FILL
            </div>
            <div className="KeratinLashLiftForNavbarContent">
            At EmbelLASHES, we want to ensure that our clients always look their best. That's why we offer lash refreshments at a discounted price, as long as at least 30% of your extensions are still attached. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
            Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
             <br></br>

</div>
            <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtn">
              <button className="buyNowKeratinLashLiftForNavbarBtn btnforInlashLiftVolume">
                Get Your Slot Now!!
              </button>
            </div>
          </div>
        </div>
      </SwiperSlide>
     
     </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
