import React,{createContext,useContext,useReducer} from "react";

// prepares the data layer

export const StateContext = createContext();

// wrap our app and provide the data layer

const  Context = ({reducer,initialState,children})=>(
    <StateContext.Provider value={useReducer(reducer,initialState)}>
    {children}
    </StateContext.Provider>
);

export default Context;

// pill info from the data layer
export const useStateValue = ()=> useContext(StateContext);