import React from 'react'
import './LashLift_TintCourse.css'
import LashLift_TintCourseImg from '../images/LashLift_TintCourseImg.jpg'
import BookNowButton from '../components/Buttons/BookNowButton'
const ClassicLashCertificationcourse = () => {
  return (
    <div className='LashLift_TintCourseMainContainer'>
    <div className='LashLift_TintCourseSection'>
    <img className='LashLift_TintCourseimage' src={LashLift_TintCourseImg} />
    <div className='LashLift_TintCourseHeading'>
    <h1 className='LashLift_TintCourseSectionHeadingInfo'>
    Lash Lift/Tint Course </h1>
    
    <div className='LashLift_TintCourseSectionInfo'>Embellashes Lash Lift/Tint Course is designed for you to have an edge in the industry. Lash lift is a technique where clients' natural eyelashes are enhanced and curled. The simplest way to think of a lash lift is thinking of it as a perm for your lashes. A simple yet effective approach. This course will allow you, as a stylist, to provide a wider range of services on your service list. With this, we will also teach you Brow Lamination as an add on!</div>
    <div className='LashLiftTintButton'><BookNowButton/></div>
    </div>
    
    </div>
    
    </div>
  )
}

export default ClassicLashCertificationcourse