// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAGvBXJPzYi-g40V-OkQnpumi563y7Xfno",
    authDomain: "embellashes-9698a.firebaseapp.com",
    projectId: "embellashes-9698a",
    storageBucket: "embellashes-9698a.appspot.com",
    messagingSenderId: "781439918594",
    appId: "1:781439918594:web:7179226288023cc0e87d1b",
    measurementId: "G-JZ36BEBJTM"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage(); 

export {db,auth,storage};