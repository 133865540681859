
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import ClassicLashCertificationcourseImg from '../../images/ClassicLashCertificationCourseImg.png'

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";
import BookNowButton from '../Buttons/BookNowButton'
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const CoursesForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerCourses'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImg" src={ClassicLashCertificationcourseImg}/>
    <div className="  KeratinLashLiftForNavbarInfoClassicLasCertificationCourse{
      ">
    <div className="KeratinLashLiftForNavbarHeadingCourses">Classic Lash Certification Course</div>
    <div className="KeratinLashLiftForNavbarContentCourses">Classic Certifications Program is designed for starting lash artists with no previous experience focusing on beginning your new career as smooth as possible with full understanding of the lash artistry. Classic Lashing technique which is adding a single lash extension to a single natural eyelash, giving it a longer, slightly fuller look. In this two-day classic lash extension course, you will learn a lot including product knowledge, full sets, fills, removals, client aftercare, eye design, and more.
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnCourses">
    <BookNowButton/></div>
    </div>
    </div>
    
    </SwiperSlide>
           
  </Swiper>
    </div>
  )
}

export default CoursesForNavbar