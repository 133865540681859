import React from 'react'
import './BookNowButtonMicrobladingPage.css'
import { Link } from 'react-router-dom'
const Button = () => {
  return (
  //  <Link to='/bookNowButtonOnTheMicroBladingPage'>
  <a href='https://www.vagaro.com/embellashes' target="_blank">
  <button className='bookNowButtonOnTheMicroBladingPage'>Get Your Slot Now!</button>
  </a>
   
  //  </Link>
  );
}

export default Button