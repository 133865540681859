
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import KeratinLashLiftServiceImg from '../../images/KeratinlashLiftService.jpg'
import InLeiLashLiftAndFillersImg from '../../images/InLeiLashLiftAndFillersImg.jpg'
import AnnosuneImg from '../../images/AnnosuneImageWithBg.png'
import Jackie from "../../images/jackie.png";
import Shelly from "../../images/shellyArtist.png";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const AnnosuneNForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainerArtistsShelly'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
    <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImgArtists" src={Shelly}/>
    <div className="KeratinLashLiftForNavbarInfoAnnosune">
    <div className="KeratinLashLiftForNavbarHeadingArtists">Shelly C</div>
    <div className="KeratinLashLiftForNavbarPrice">     (559) 530-0309</div>
    <div className="KeratinLashLiftForNavbarContentArtists">    I am a Licensed Esthetician and Certified Embellashes Artist, I
    have 7 years of experience in Lashing and extremely excited to be
    apart of the Embellashes Team
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnArtistsJackie">
    <button className="buyNowKeratinLashLiftForNavbarBtnArtists"> Schedule An Appointment Now!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    
  </Swiper>
    </div>
  )
}

export default AnnosuneNForNavbar