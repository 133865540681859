import React from 'react'
import './ReadMoreEyelashExtensionCostInFresno.css'
import { Link } from 'react-router-dom'
const Button = () => {
  return (
   <Link to='/ReadMoreEyelashExtensionCostInFresno'>
   <button className='btnReadmoreEyelashExtensionCostInFresno'>Read More</button>
   </Link>
  );
}

export default Button