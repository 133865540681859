import React from 'react'
import './WaxingPage.css'
import upperLipWaxingImg from '../images/upperLipWaxing.jpg'
import InLeiLashLiftAndFillersImg from '../images/InLeiLashLiftAndFillersImg.jpg'
import BookNowButton from '../components/Buttons/BookNowButton'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import MobileNavbar from '../components/Accordion/MobileNavbar'
const WaxingPage = () => {
  return (
    <div className='WaxingPageMainContainer'>
    <div className='NavbarConatinerOfWaxingPage'><Navbar/>
    </div>
    <div className='MobileNavbarWaxing'><MobileNavbar/></div>
    <div className='HeadingOfWaxingPage'> Our Waxing services</div>
      <div className='WaxingPageServiceSection'>
      <img className='WaxingPageServiceimage upperLipWaxingImg' src={upperLipWaxingImg}/>
        <div className='WaxingPageServiceHeading'>
        <div className='WaxingPagePrice'>$15.00</div>
         <h1 className='WaxingPageServiceSectionHeadingInfo'>
         Upper Lip Waxing </h1>
    
            <div className='WaxingPageServiceSectionInfo'>Have painless upper-lip waxing for just $15!
            </div>
            <div className='bookNowButtonForWaxingPageServicePage'>  <BookNowButton/></div>
  
        </div>
      </div>
      <div className='SideburnsWaxingPageServiceSectionForMarginBottom'>
      <div className='SideburnsWaxingPageServiceSection'>
      <img className='SideburnsWaxingPageServiceimage' src={InLeiLashLiftAndFillersImg}/>
        <div className='SideburnsWaxingPageServiceHeading'>
        <div className='SideburnsWaxingPageLiftPrice'>$80.00</div>
         <h1 className='SideburnsWaxingPageServiceSectionHeadingInfo'>
        Sideburns Waxing</h1>
    
            <div className='SideburnsWaxingPageServiceSectionInfo'>Take sideburns waxing to go your makeup on your face evenly
            </div>
            <div className='bookNowButtonForSideburnsWaxingPageServicePage'>  <BookNowButton/></div>
  
        </div>
      </div>
     </div>
     <div className='SideburnsWaxingPageServiceSectionForMarginBottom'>
     <div className='SideburnsWaxingPageServiceSection'>
     <img className='SideburnsWaxingPageServiceimage fullfaceWaxingImg' src={InLeiLashLiftAndFillersImg}/>
       <div className='SideburnsWaxingPageServiceHeading'>
       <div className='SideburnsWaxingPageLiftPrice'>$60.00</div>
        <h1 className='SideburnsWaxingPageServiceSectionHeadingInfo'>
        Full Face Waxing</h1>
   
           <div className='SideburnsWaxingPageServiceSectionInfo'>Have smooth and hassle-free skin for weeks with our full face waxing.
           </div>
           <div className='bookNowButtonForSideburnsWaxingPageServicePage'>  <BookNowButton/></div>
 
       </div>
     </div>
    </div>
    <div className='SideburnsWaxingPageServiceSectionForMarginBottom'>
    <div className='SideburnsWaxingPageServiceSection'>
    <img className='SideburnsWaxingPageServiceimage BrowsWaxingImg' src={InLeiLashLiftAndFillersImg}/>
      <div className='SideburnsWaxingPageServiceHeading'>
      <div className='SideburnsWaxingPageLiftPrice'>$25.00</div>
       <h1 className='SideburnsWaxingPageServiceSectionHeadingInfo'>
       Brows Waxing</h1>
  
          <div className='SideburnsWaxingPageServiceSectionInfo'>Have less painful brows waxing than threading. Just at $ 25!
          </div>
          <div className='bookNowButtonForSideburnsWaxingPageServicePage'>  <BookNowButton/></div>

      </div>
    </div>
   </div>

     <Footer/>
    </div>
  )
}

export default WaxingPage