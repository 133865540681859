import React from 'react'
import EyeBrowHome2 from '../images/eyebrowComponent1Slider2.jpg'
import './EyeBrowExtensionHome2.css'
import CheckAvailableDates from './Buttons/CheckAvailableDates'

const EyeBrowExtensionHome2 = () => {
  return (
    <div className='mainFlexEyeBrowHomeContainer'>
   {/* <div className='mainflexEyeBrowHomeContainerHeading'>
    <p className='mainflexEyeBrowHomeContainerHeading1'>Good brows. Good mood.   </p>
    <p className='mainflexEyeBrowHomeContainerHeading2ndPart'>Good day.</p>
   
    <p  className='mainflexEyeBrowHomeContainerHeading3'>Get your microblading done by industry experts in fresno,CA  </p>
    <p className='mainflexEyeBrowHomeContainerHeading4'></p>
 
  
  </div>*/}
  <img className='rightImgEyeBrowHomeFlexContainer'  src={EyeBrowHome2}/>
  <div className='mainFlexEyebrowContainerContent1'>Virtual tryon for </div>
  <div className='mainFlexEyebrowContainerContent2'> eyelash extenion and microblading </div>
  <div className='buttonCSS'><CheckAvailableDates/></div>
    </div>
  )
}

export default EyeBrowExtensionHome2