import React from 'react'
import './ReadMoreSmileThearpyCostInFresno.css'
import { Link } from 'react-router-dom'
const Button = () => {
  return (
   <Link to='/ReadMoreSmileTherapyCostInFresno'>
   <button className='btnReadMoreSmileTherapy'>Read More</button>
   </Link>
  );
}

export default Button