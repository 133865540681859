
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import VolumeLashExtensionCourseImg from '../../images/VolumeLashExtensionCourseImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";
import BookNowButton from '../Buttons/BookNowButton'
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const VolumelashCertificationForNavbar = () => {
  return (
    <div className='KeratinLashLiftForNavbarMainContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
  >
        <SwiperSlide>
    <div className="KeratinLashLiftForNavbarConatiner">
    <img className="KeratinLashLiftForNavbarImg" src={VolumeLashExtensionCourseImg}/>
    <div className="KeratinLashLiftForNavbarInfoVolumeLasCertificationCourse">
    <div className="KeratinLashLiftForNavbarHeadingVolumeCourse ">  Volume Lash Extension Course </div>
    <div className="KeratinLashLiftForNavbarContentVolumeCourse">Embellashes Volume Course is designed to level up your lashing career. Volume lashing is placing a multiple extension fan to a single natural eyelash.This course is for those who are already proficient in classic lashing technique..
    </div>
    <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnCourses">
    <BookNowButton/>
    </div>
    </div>
    </div>
    </SwiperSlide>
   
    
  </Swiper>
    </div>
  )
}

export default VolumelashCertificationForNavbar