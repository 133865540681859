import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import MegavolumeForAnnosuneImg from '../../images/upperLipWaxing.jpg'
import HybridsLashFullLiftAnnosuneImg from '../../images/HybridsByAnnosuneImg.jpg'
import HybridsLashFullLiftHappyImg from '../../images/HybridsBYHappyimg.jpg'
import  HybridsLashFullLiftJackieImg from '../../images/hybridLashFillJackie.jpg'
import HybridsLashFullLiftShellyImg from '../../images/HybridsBySheelyImg.jpg'
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./KeratinLashLiftComponentForNavbar.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

const ClassicsForNavbar = () => {
  return (
    <div className="KeratinLashLiftForNavbarMainContainerMegavolume">
      <Swiper
        slidesPerView={1}
        spaceBetween={300}
        centeredSlides={true}
        autoplay={{
          delay: 7500,
          disableOnInteraction: true,
        }}
       
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper KeratinLashLiftForNavbarSwiper classForBackgroundColor"
      >

        <SwiperSlide>
        
          <div className="KeratinLashLiftForNavbarConatinerMegaVolume">
          
            <img
              className="KeratinLashLiftForNavbarImg"
              src={MegavolumeForAnnosuneImg}
            />
            
            <div className="KeratinLashLiftForNavbarInfoMegavolume">
              <div className="KeratinLashLiftForNavbarPriceMegavolume">$375.00</div>
              <div className="KeratinLashLiftForNavbarHeading">
              M V LASH FULL SET
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              If you're looking for a stunning, voluminous lashes, our Russian Volume Full Set is perfect for you! We apply 8-16 super fine, super light lashes to each natural lash, fanning them out by hand to create a gorgeous, full look. These lashes are so light and delicate that they won't damage your natural lashes. With Russian Volume, you can have up to 600 per eye - that's way more than classic single lashes (which max out at around 180 per eye).
              <br></br>
              
  
              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnMegaVolume">
              <Link to='/ClassicsExtenionPage'>
                <button className="buyNowKeratinLashLiftForNavbarBtnMegaVolume">
                  Get Your Slot Now!!
                </button>
                </Link>
              </div>
            </div>
          </div>
          
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerMegaVolume">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={MegavolumeForAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfoMegavolume">
              <div className="KeratinLashLiftForNavbarPriceMegavolume">$195.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
              2 WEEKS M V LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Only valid for existing Embellashes clients.That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
              <br></br>

              </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnMegaVolume">
                <button className="buyNowKeratinLashLiftForNavbarBtnMegaVolume ">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="KeratinLashLiftForNavbarConatinerMegaVolume">
            <img
              className="KeratinLashLiftForNavbarImg"
              src={MegavolumeForAnnosuneImg}
            />
            <div className="KeratinLashLiftForNavbarInfoMegavolume">
              <div className="KeratinLashLiftForNavbarPriceMegavolume">$180.00</div>
              <div className="KeratinLashLiftForNavbarHeading forInleiLashLiftHeading">
              3 WEEKS MV LASH FILL
              </div>
              <div className="KeratinLashLiftForNavbarContent">
              Only valid for existing Embellashes clients. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
              Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
               <br></br>         </div>
              <div className="ContainerForbuyNowKeratinLashLiftForNavbarBtnMegaVolume">
                <button className="buyNowKeratinLashLiftForNavbarBtnMegaVolume ">
                  Get Your Slot Now!!
                </button>
              </div>
            </div>
          </div>
        </SwiperSlide>
        
     </Swiper>
    </div>
  );
};

export default ClassicsForNavbar;
