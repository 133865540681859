import React from 'react'
import Navbar from '../components/Navbar'
import NewProducts from '../components/NewProducts'
const AllProductsCard = ({products}) => {
  return (
    <div>
    <Navbar/>
    {products?.map((product) => {
   
        return (
          
       
           <div>
         
            <NewProducts key={product.ID} NewProducts={product}  />
 

        </div>
         )
        }
      )}
    </div>
  )
}

export default AllProductsCard