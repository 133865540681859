import { useState } from "react";
import { navItems } from "./MobileNavbarApi";
import "./MobileNavbar.css";
import logoFooter from "../../images/embellashesLogoFooter.png";
import { AiOutlineMenu } from "react-icons/ai";

import { Link } from "react-router-dom";
import MyAccordion from "./MyAccordion";
const MobileNavbar = () => {
  const [data, setData] = useState(navItems);
  const [openMenu, setOpenMenu] = useState(false);
  const [sticky, setSticky] = useState(false);


 
  return (
    <>
      <div className="mobileNavbarFlex">
        <div className="logoMobileNavbar">
          <h1 className="companyNameMobileNavbar">
            <Link className="menu-link-CompanyNameMobileNavbar" to="/">
              <img src={logoFooter} className="companyNameImgMobileNavbar" />
            </Link>
          </h1>
        </div>
        <div>
          {" "}
          <AiOutlineMenu
            onClick={() => setOpenMenu(!openMenu)}
            style={{ color: "white", fontSize: "30px", margin: "10px" }}
          />
        </div>
      </div>
      {openMenu && (
        <section className="main-div">
          {data.map((curElm) => {
            const { id } = curElm;
            return <MyAccordion key={id} {...curElm} />;
          })}
        </section>
      )}
    </>
  );
};

export default MobileNavbar;
