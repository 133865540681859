import React from 'react'
import eyebrowsSectionImg from '../images/eyebrowsSectionImg.jpg'
import './EyebrowservicesComponent.css'
import BookNowButton from '../components/Buttons/BookNowButton'
const eyebrowServicesComponent = () => {
  return (
    <div className='EyebrowServicesMainContainer'>
    <div className='eyebrowsSection'>
        
        <div className='eyebrowSectionHeading'>
            <h1 className='eyebrowsSectionHeadingInfo'>
            Eyebrows</h1>
        
             <div className='eyebrowSectionInfo'>Embellashes focuses on Eyebrow Microblading. With that we also provide 
             <div className='lastlineOf2ndBlock'>services like Henna Brows, Eyebrow Lamination and many more!</div>
             </div>
             
             <BookNowButton/>
        </div>
        <img className='eyebrowsSectionimage' src={eyebrowsSectionImg}/>
        </div>
    </div>
  )
}

export default eyebrowServicesComponent