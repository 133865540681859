import React from 'react'
import EyeLashExtensionServiceComponent from '../components/EyeLashExtensionServiceComponent'
import EyebrowServicesComponent from '../components/EyebrowServicesComponent'
import SmileThearpyServicesComponent from '../components/SmileThearpyServicesComponent'
import './Service.css'
import Footer from '../components/Footer'
import EyeBrowServices2 from '../components/EyeBrowServices2'
import EyeLashServices2 from '../components/EyeLashServices2'
const Services = () => {
  return (
    <>
    <div className='servicesMainContainer'>
    <h1 className='servicesHeading'>
  SERVICES AVAILABLE</h1></div>
  <EyeBrowServices2/>
  <EyeLashServices2/>
     <EyeLashExtensionServiceComponent/>
      <EyebrowServicesComponent/>      
      <SmileThearpyServicesComponent/>  
     <Footer/>
     </>


  )
}

export default Services