import React,{useState,} from "react";
import logoFooter from '../images/embellashesLogoFooter.png'
import './SignUp.css'
import { Link,useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { auth,db } from "../Firebase";

const SignUp = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [FullName, setFullName] = useState('');
    const [successMsg, setSuccessMsg] = useState('');


    const Register = e=>{
        e.preventDefault();
        auth
        .createUserWithEmailAndPassword(email,password)
        .then((auth)=>{
            // console.log(auth);
            db.collection('NewAccount').doc(auth.user.uid).set({
                FullName:FullName,
                Email:email,
                Password:password
            }).then(()=>{
                setSuccessMsg('SignUp successful. Now you will get automatically directed to Login Page ')
                setFullName('');
                setEmail('');
                setPassword('');
                setTimeout(()=>{
                    setSuccessMsg('');
                    navigate('/');

                },3000)
            }).catch(e=> alert(e.message))
            // somefancy firebase register shitttt...
            
           
        })
        
        .catch(e=> alert(e.message))
        // somefancy firebase register shitttt...
        }


  return (
    <div className="bodyOfSignUpPage">
      <div className="cardOfSignUpPage">
        <img src={logoFooter} className="logoImageOfSignUp" />
        <div className="SignUpPageHeading">Create a new account</div>
        {successMsg &&<div>
            <div className="success-msg">{successMsg}</div>
           
            </div>}
        <form>
        <h1 className="FullNameHeading">Your Name</h1>
        <input
          type="text"
          value={FullName}
          onChange={(e) => setFullName(e.target.value)}
          className="FullNameInput"
          placeholder="First and last name"
        />
      
          <h1 className="emailHeading">Email</h1>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="emailInput"
            placeholder="Enter your Email"
          />
          <h1 className="emailHeading">Password</h1>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="emailInput"
            placeholder="Enter your Email"
          />

          <div className="SignUpButton">
            <Link to="/signedIn">
              <button
                className="SignUpButtonProcess"
                // onClick={SignIn}
                type="submit"
                onClick={Register}
              >
                Sign Up
              </button>
            </Link>
          </div>
          
           
        
        </form>
      </div>
    </div>
  );
};

export default SignUp;
