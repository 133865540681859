
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../../images/JackieWeek2ClassiclashFill.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Annosune Services/ClassicsByAnnosune.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";



  
const ClassicsByannosune = () => {
  return (
    <div className='ClassicsByannosuneContainer'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 7500,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$65.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">Welcome to EmbelLASHes! We are dedicated to providing our clients with the best possible experience. To ensure that all our clients receive the highest quality service, we require that all extensions be attached prior to their appointment. If less than 40% of extensions are attached, you will be charged the after 3 week price.<br></br><br></br> Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
    <br></br><br></br>
   **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$80.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br><br></br>
        **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$95.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">4 WEEKS CLASSIC LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent">At EmbelLASH, we want to ensure that our clients are getting the most out of their lash extension experience. That's why we require that at least 30% of extensions be attached after 3 weeks. If less than 30% are attached, you will be bumped up to the 4 week price.


    <br></br><br></br>
    Please arrive with squeaky clean lashes to avoid a *$35 makeup removal fee
         <br></br><br></br>
        **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
    </Swiper>
    </div>
  )
}

export default ClassicsByannosune