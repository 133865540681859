import React from 'react'
import smileTherapySectionImg from '../images/smilethearpySectionImg.jpg'
import BookNowButton from '../components/Buttons/BookNowButton'
import './SmileThearpyServicesComponent.css'
const SmileThearpyServicesComponent = () => {
  return (
    <div className='smileTherapyServicesMaincontainer'>
    <div className='smileTherapySection'>
        <img className='smileTherapySectionimage' src={smileTherapySectionImg}/>
        <div className='smileTherapySectionHeading'>
        <h1 className='smileTherapySectionHeadingInfo'>
        Smile Therapy </h1>
        
            <div className='smileTherapySectionInfo'>Smile Therapy focuses on Teeth Whitening, helping you whiten your teeth from 5 to 14 shades!</div>
       
<BookNowButton/>
</div>
  </div>
    </div>
  )
}

export default SmileThearpyServicesComponent