import './Artists.css'
import Annosune from './Artists/Annosune'
import Jackie from './Artists/Jackie'
import Katelyn from './Artists/Katelyn'
import IndividualArtist from './Artists/IndividualArtist'
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from 'react-router-dom'
const Artist = ({artists}) => {
  // console.log(artists);
  return (
    <div className='mainArtistsContainer'>
    <h1 className='headingArtists'>OUR HIGHLY EXPERIENCED TEAM</h1>
    <Swiper
        slidesPerView={2}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          
        }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
      
      <div className='AnnosuneANdJackiContainer'>
      {artists?.map((individualArtist)=>(
        <SwiperSlide> <Link to='/artists'><IndividualArtist key={individualArtist.ID} individualArtist={individualArtist} /></Link></SwiperSlide>
      ))} 
      
    
        </div>
        
      </Swiper>

    // 
        {/*<div className='AnnosuneANdJackiContainer'>
        <Annosune/><Jackie/>
        </div>
        <div className='katelynContainer'>
      <Katelyn/>*/}
      </div>
       
    
  )
}

export default Artist