import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import BuyNow from "../components/Buttons/BuyNow";
import { useStateValue } from "../components/context/Context";
import "./NewProducts.css";
import data from "./db.json";
let tempArray = [];
const NewProducts = ({ NewProducts: NewProducts1, addToCart }) => {
  const [localItem, setLocalItem] = useState(
    JSON.parse(localStorage.getItem("basketItem")) || []
  );
  const [{ basket }, dispatch] = useStateValue();
  // JSON.parse(localStorage.getItem("basketItem")) ||
  useEffect(() => {
    // console.log(data.products, "hi this is storage item 1");
    if (
      JSON.parse(localStorage.getItem("basketItem")) &&
      tempArray.length == 0
    ) {
      tempArray = JSON.parse(localStorage.getItem("basketItem"));
    }
    localStorage.setItem("basketItem", JSON.stringify(tempArray));
    dispatch({
      type: "ADD_TO_BASKET",
      item: tempArray,
    });
    // console.log(basket, "hi this is storage item");
  }, [localItem]);

  // const [{ basket }, dispatch] = useStateValue();
  // console.log(basket, "kkkkkkkkkkkkkkkkkk");
  const addToBasket = (e) => {
    // dispatch some action into the data layer\
    let newItem = {
      id: NewProducts1.id,
      title: NewProducts1.title,
      image: NewProducts1.url,
      price: NewProducts1.price,
    };

    dispatch({
      type: "ADD_TO_BASKET",
      item: newItem,
    });
    tempArray.push(newItem);
    // console.log(localItem, "boefore");
    setLocalItem([...localItem, newItem]);
    // console.log(localItem, "after");
  };

  return (
    <div className="NewProductsContainer">
      <div className="NewProductsCard">
        <div className="imgFolder">
          <img
            className="NewProductsImg"
            src={NewProducts1.url}
            alt={NewProducts1.title}
          />
        </div>
        <h2 className="Newproduct2Price">${NewProducts1.price}</h2>
        <h1 className="Newproduct2Heading">{NewProducts1.title}</h1>

        <div className="buyNowButtonForIndividualProducts">
          {" "}
          <div>
            {data.products.map((item, idx) => {
              // console.log(item.name, "itemname");
              if (item.name == NewProducts1.title) {
                // console.log(item.desc, "item.desc");

                return (
                  <BuyNow
                    description={item.desc}
                    name={item.name}
                    price={item.price}
                    image={item.imgSrc}
                    id={item.id}
                  />
                );
              }
            })}
          </div>
        </div>

        <button className="NewaddToCartButton" onClick={addToBasket}>
          Add To Cart
        </button>
      </div>
    </div>
  );
};

export default NewProducts;
