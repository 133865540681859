import React,{useState} from 'react'
import { storage,db } from '../Firebase';
const AddProducts = () => {

    const [title,setTitle] = useState('');
    const [price,setPrice] = useState('');
    const [image,setImage] = useState(null);
    const [sucessMsg,setSuccessMsg] = useState('');
    const [uploadError,setUploadError] = useState('');
    const [imageError,setImageError] = useState('');

    const types=['image/jpg','image/jpeg','image/png','image/PNG'];

    const handleProductImg=(e)=>{

        let selectedFile= e.target.files[0];
        if(selectedFile){
            if(selectedFile && types.includes(selectedFile.type)){
                setImage(selectedFile);
                setImageError('')

            }else{
                setImage(null);
                setImageError('please select a valid file type')

            }

        }else{
            console.log('please select your file');
        }
    }

    const handleAddProducts=(e)=>{
        e.preventDefault();
        // console.log(title,price);
        // console.log(image);
       const uploadTask =storage.ref(`product-images/${image.name}`).put(image);

       uploadTask.on('state_changed',snapshot=>{
        const progress= (snapshot.bytesTransferred/snapshot.totalBytes)*100
        console.log(progress);
       },error=>setUploadError(error.message),()=>{
        storage.ref('product-images').child(image.name).getDownloadURL().then(url=>{
            db.collection('EmbellashProducts').add({
                title,
                price:Number(price),
                url
            }).then(()=>{
                setSuccessMsg('product added successfully');
                setTitle('');
                setPrice('');
                document.getElementById('file').value='';
                setImageError('');
                setUploadError('');
                setTimeout(()=>{
                    setSuccessMsg('');
                },3000)
            }).catch(e=>setUploadError(e.message));
        })
       })
    }




  return (
    <div className='AddProductsContainer'>
    <form onSubmit={handleAddProducts}>
        <div className='AddProductsHeading'>Add Products</div>
        {sucessMsg && <div>
            <div className='sucessMsg'>{sucessMsg}</div>
            </div>}
        <div className='AddProductsTitle'> Product Title
        <input type="text" onChange={(e)=>setTitle(e.target.value)} value={title}></input>
        </div>
        <div className='AddProductsPrice'> Products Price
        <input type="text" onChange={(e)=>setPrice(e.target.value)} value={price}></input>
        </div>
        <div className='AddProductsImage'> Upload Image
        <input type="file" id='file' onChange={handleProductImg} ></input>
        {imageError && <div>
            <div className='errorImage-Msg'>{imageError}</div>
            </div>}
        </div>
        <div className='AddProductsButton'>
        <button type='submit'>Submit</button></div>
        {uploadError && <div>
            <div className='errorImg-Msg'>{uploadError}</div>
            </div>}
            </form>
    
    
    
    </div>
  )
}

export default AddProducts