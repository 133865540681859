import React from "react";
import "./KertainLashLiftPage.css";
import KeratinLashLiftServiceImg from "../images/KeratinlashLiftService.jpg";
import InLeiLashLiftAndFillersImg from "../images/InLeiLashLiftAndFillersImg.jpg";
import BookNowButton from "../components/Buttons/BookNowButton";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import MobileNavbar from "../components/Accordion/MobileNavbar";
const KeratinLashLiftServicePage = () => {
  const mobileNavbar = document.querySelector(
    ".KeratinlashLiftPageMobileNavbar"
  );
  const navMenu = document.querySelector(".main-div");

  if (navMenu) {
   
    mobileNavbar.style.position = "static";
  }

  return (
    <div className="KeratinLashLiftServiceMainContainer">
      <div className="NavbarConatinerOfKeratinlashLiftpage">
        <Navbar />
      </div>
      <div className="KeratinlashLiftPageMobileNavbar">
        <MobileNavbar />
      </div>
      <div className="HeadingOfKeratinlashLiftPage">
        {" "}
        Our Keratin Lash Lift services
      </div>
      <div className="KeratinLashLiftServiceSection">
        <img
          className="KeratinLashLiftServiceimage"
          src={KeratinLashLiftServiceImg}
        />
        <div className="KeratinLashLiftServiceHeading">
          <div className="KeratinEyelashLiftPrice">$85.00</div>
          <h1 className="KeratinLashLiftServiceSectionHeadingInfo">
            Keratin Eyelash Lift{" "}
          </h1>

          <div className="KeratinLashLiftServiceSectionInfo">
            Keratin Lash Lift, a treatment designed to darken and lift lashes
            without adding false strands. "With this procedure your natural
            lashes are lifted up and filled with a special pigment infusion,"
            with this result you have beautiful natural lashes that curve
            upwards. 1hr service
          </div>
          <div className="bookNowButtonForKeratinLashLiftServicePage">
            {" "}
            <BookNowButton />
          </div>
        </div>
      </div>
      <div className="InleiLashListServiceSectionForGivingMargin">
        <div className="KeratinLashLiftServiceSection">
          <img
            className="KeratinLashLiftServiceimage"
            src={InLeiLashLiftAndFillersImg}
          />
          <div className="KeratinLashLiftServiceHeading">
            <div className="KeratinEyelashLiftPrice">$110.00</div>
            <h1 className="KeratinLashLiftServiceSectionHeadingInfo">
              InLei Eyelash Lift + Fillers{" "}
            </h1>

            <div className="KeratinLashLiftServiceSectionInfo">
              Lash Filler is a treatment, that will not only lift your lashes
              and add them a curl, but will also improve the condition and
              health of your natural lashes, it is the first treatment in the
              world, that by its clinical test has proven that increases the
              thickness of natural lashes by 24%! 1hr 15min service
            </div>
            <div className="bookNowButtonForKeratinLashLiftServicePage">
              {" "}
              <BookNowButton />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default KeratinLashLiftServicePage;
