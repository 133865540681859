import React from 'react'
import './MyAccordion.css'
import { useState } from 'react'
import {MdKeyboardArrowDown} from 'react-icons/md'
import MyAccordionChildsChild from './MyAccordionChildsChild'
const MyAccordionChild = ({navItem1,navItem1Child1,navItem2,path}) => {
    const [show, setShow] = useState(false)
    if( navItem1Child1){
  return (
    <div>
    <div className='main-heading-MyAccoridion'>
    <a href={path || '#'} > <h1 className='MyAccordion-Item1'>{navItem1}</h1></a>
    <div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>
 
    </div>
    {show && 
    <h2 className='MyAccordion-Item2'>{navItem1Child1.map((curElm)=>{
     const {navItem2}= curElm;
    
     return(
        
         <MyAccordionChildsChild key={navItem2} {...curElm} />
         
     )
    })}</h2>
    }
    </div>
  
  )
    }else{
      return (
        <div>
        <div className='main-heading-MyAccoridion'>
        <a href={path || '#'} > <h1 className='MyAccordion-Item1'>{navItem1}</h1></a>
        {/*<div className='dropdownIcon' onClick={()=>setShow(!show)}><MdKeyboardArrowDown style={{color:'white', fontSize:'24px'}}/></div>*/}
     
        </div>
        {show && 
        <h2 className='MyAccordion-Item2'>{navItem1Child1.map((curElm)=>{
         const {navItem2}= curElm;
        
         return(
            
             <MyAccordionChildsChild key={navItem2} {...curElm} />
             
         )
        })}</h2>
        }
        </div>
      
      )
    }
}

export default MyAccordionChild