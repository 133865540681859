import React from 'react'
import './VolumeLashExtensionCourse.css'
import VolumeLashExtensionCourseImg from '../images/VolumeLashExtensionCourseImg.jpg'
import BookNowButton from '../components/Buttons/BookNowButton'
const VolumeLashExtensionCourse = () => {
  return (
    
    <div className='VolumeLashExtensionCertificationCourseMainContainer'>
    <div className='VolumeLashExtensionCertificationCourseSection'>
    <img className='VolumeLashExtensionCertificationCourseimage' src={VolumeLashExtensionCourseImg} />
    <div className='VolumeLashExtensionCertificationCourseHeading'>
    <h1 className='VolumeLashExtensionCertificationCourseSectionHeadingInfo'>
    Volume Lash Extension Course </h1>
    
    <div className='VolumeLashCertificationCourseSectionInfo'>Embellashes Volume Course is designed to level up your lashing career. Volume lashing is placing a multiple extension fan to a single natural eyelash.This course is for those who are already proficient in classic lashing technique..</div>
    <div className='BookNowButtonOfVolumeLashExtensioncourse'><BookNowButton/></div>
    </div>
    
    </div>
    
    </div>
  )
}

export default VolumeLashExtensionCourse