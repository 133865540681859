import Navbar from '../components/Navbar'
import './MegaVolumeExtensionPage.css'

import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ClasiicLashFullLiftAnnosuneImg from '../images/upperLipWaxing.jpg'
import VolumeLashFullLiftAnnosuneImg from '../images/2WeekVolumeLashFillImg.jpg'
import Week3VolumeLashFillImg from '../images/3WeekVolumeLashFillImg.jpg'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";



// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import Footer from '../components/Footer';
import MobileNavbar from '../components/Accordion/MobileNavbar';
const MegaVolumeExtensionPage = () => {
  return (
    <div>
    <div className='classicExtensionPageNavbar'><Navbar/></div>
    <div className='classicExtensionsPageMobileNavbar'><MobileNavbar/></div>
    <div className='MegaVolumeExtensionPageConatiner-openThroughNavbar'>
    <div className='MegaVolumeExtensionPageConatiner-openThroughNavbar-heading'>
     Mega Volume Eyelash Extension
    </div>

    <div className='MegaVolumeExtensionPageConatiner-openThroughNavbar-MegaVolumeByAnnosuneHeading'>
    Mega volume By Annosune
    </div>
    <div className='HybridsByannosuneContainer-forMegaVolumeEyelashExtensionpage'>
    <Swiper
    slidesPerView={1}
    spaceBetween={300}
    centeredSlides={true}
    autoplay={{
      delay: 750000000,
      disableOnInteraction: true,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper classicsByAnnosuneswiper responsiveSwiperForMegaVolumeExtensionpage"
  >
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$375.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">M V LASH FULL SET</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">If you're looking for a stunning, voluminous lashes, our Russian Volume Full Set is perfect for you! We apply 8-16 super fine, super light lashes to each natural lash, fanning them out by hand to create a gorgeous, full look. These lashes are so light and delicate that they won't damage your natural lashes. With Russian Volume, you can have up to 600 per eye - that's way more than classic single lashes (which max out at around 180 per eye).
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    
    </SwiperSlide>
    <SwiperSlide><div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$195.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">2 WEEKS MV LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Only valid for existing Embellashes clients.That's why we offer lash refreshments at a discounted price, as long as at least 40% of your extensions are still attached. <br></br><br></br> If less than 40% are attached, you will be charged the after-3-week price.
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div>
    </SwiperSlide>
    <SwiperSlide>
    <div className="ClasiicLashFullLiftAnnosuneConatiner">
    <img className="ClasiicLashFullLiftAnnosuneImg" src={ClasiicLashFullLiftAnnosuneImg}/>
    <div className="ClasiicLashFullLiftAnnosuneInfo">
    <div className="ClasiicLashFullLiftAnnosunePrice">$180.00</div>
    <div className="ClasiicLashFullLiftAnnosuneHeading">3 WEEKS MV LASH FILL</div>
    <div className="ClasiicLashFullLiftAnnosuneContent-ClassicsExtensionpage">Only valid for existing Embellashes clients. <br></br><br></br> If less than 30% are attached, you will be charged the after-3-week price.
    Please arrive with squeaky clean lashes otherwise a $35 make-up removal fee will be added!
     <br></br><br></br>
    **This is a 2-2.5 hrours service so if you need to cancel your appointment, please do so at least 48 hours in advance. If you do not show up for your appointment or cancel with less than 48 hours notice, you will be charged the full price.
    </div>
    <div className="ContainerForbuyNowClassicLashFullLiftByAnnosuneBtn">
    <button className="buyNowClassicLashFullLiftByAnnosuneBtn">Get Your Slot Now!!</button></div>
    </div>
    </div></SwiperSlide>
   
  </Swiper>
    </div>
    <div className='backgroundColorLineFor-MegaVolumeExtensionPageConatiner'></div>

    </div>
    <Footer/>
    </div>
  )
}

export default MegaVolumeExtensionPage